import React, { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button, Dialog, Grid, Select, TextField, Typography, useSnackbar } from '@sprnova/nebula';
import { useCreateFivetranConnectorMutation } from 'api/crudGraphQL/fivetran/createFivetranConnector';
import NebulaDatasourceLogo from 'components/NebulaDatasourceLogo/NebulaDatasourceLogo';
import { InternalGoogleDialogProps } from '../InternalGoogleDialogProps';


const InternalGoogleAdsDialog = ({client, openDialog = false}: InternalGoogleDialogProps): JSX.Element => {
  const [createFivetranConnector, {isLoading: createConnectorLoading}] = useCreateFivetranConnectorMutation();
  const history = useHistory();
  // Snackbar for error message display
  const { addSnackbar } = useSnackbar();

  // State to manage the Dialog open/close
  const [open, setOpen] = useState(openDialog);

  // Function to toggle Dialog state
  const toggle = (): void => {
    setOpen((prev) => !prev);
  };

  // useForm hook for handling form submission
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      email: '',
      customer_id: '',
      account_id: ''
    },
  });

  // Watch form values
  const email = watch('email');
  const customer_id = watch('customer_id');

  // Conditions for disabled buttons
  const disableButtons = !email || !customer_id || createConnectorLoading;

  // Submit handler
  const onSubmit = async (data: { email: string, customer_id: string }): Promise<void> => {
    if(email && customer_id && client?.group_id && client?.id) {
      try {
        await createFivetranConnector({
          group_id: client?.group_id,
          service: 'google-ads',
          is_created_by_client: false,
          attributions: '',
          auth_email: data.email,
          customer_id: customer_id,
          account_id: customer_id
        });

        addSnackbar({
          variant: 'success',
          message: 'Connector successfully created',
          persist: false,
        });

        // Wait for snackbar animation to complete before redirecting
        const timeoutId = setTimeout(() => {
          history.push(`/power-view/accounts/${client.id}/integrations`);
          clearTimeout(timeoutId); // clear the timeout after it executes
        }, 1000);

      } catch (error) {
        addSnackbar({
          variant: 'error',
          message: `Failed to create connector. Error: ${error}`,
          persist: false,
        });
      }
    }
  };

  return (
    <>
      {!openDialog && (
        <Button variant="primary" onClick={toggle} size='medium'>
            Connect
        </Button>
      )}
      <Dialog
        open={open}
        onClose={toggle}
        title={
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <NebulaDatasourceLogo slug="google-ads" />
            </Grid>
            <Grid item>
              <Typography variant='h3'>Google Ads</Typography>
            </Grid>
          </Grid>
        }
        size="sm"
        primaryButtonProps={{
          children: createConnectorLoading ? 'Loading...' : 'Submit',
          type: 'submit',
          onClick: handleSubmit(onSubmit),
          disabled: disableButtons
        }}
        secondaryButtonProps={{ children: 'Cancel', onClick: toggle, disabled: disableButtons }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="email"
                  label="Email"
                  helperText="Select a Power Digital email"
                  placeholder="Choose email"
                >
                  <Select.Item value="analytics">analytics@powerdigtialmarketinginc.com</Select.Item>
                  <Select.Item value="ecom">ecomm@powerdigtialmarketinginc.com</Select.Item>
                  <Select.Item value="contact">contact@powerdigtialmarketinginc.com</Select.Item>
                  <Select.Item value="b2b">b2b@powerdigtialmarketinginc.com</Select.Item>
                  <Select.Item value="b2c">b2c@powerdigtialmarketinginc.com</Select.Item>
                  <Select.Item value="seoanalytics">seoanalytics@powerdigtialmarketinginc.com</Select.Item>
                  <Select.Item value="paidanalytics">paidanalytics@powerdigtialmarketinginc.com</Select.Item>
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="customer_id"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="customer_id"
                  label="Customer ID"
                  helperText="Input Customer ID"
                  placeholder="XXX-XXX-XXXX"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Please validate these values are correct before submitting, incorrect values may result in an incomplete connector created.</Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default memo(InternalGoogleAdsDialog);
