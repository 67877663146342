import React, { memo, useMemo } from 'react';
import { InfoTooltip, Typography, Grid, Box } from '@sprnova/nebula';

const InfoTooltipBlueprintVabo = () => {

  const contentInfo = useMemo(() => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography marginBottom={'6px'} fontWeight={400} paragraph display={'inline'}>
              This is any contract structure that allows for a variable bill calculation such as a % of ad spend, % of revenue, or flat bonus
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2.4}>
            <Typography variant="h4" marginBottom={'6px'} fontWeight={600} paragraph display={'inline'}>
              Performance
            </Typography>
            <Typography marginBottom={'6px'} fontWeight={400} paragraph display={'inline'}>
              &nbsp;- A flat retainer AND either a % of growth revenue or a flat bonus based on goal achievement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" marginBottom={'6px'} fontWeight={600} paragraph display={'inline'}>
              Spend
            </Typography>
            <Typography marginBottom={'6px'} fontWeight={400} paragraph display={'inline'}>
              &nbsp;- A flat retainer OR a % of ad spend, whichever is greater
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }, []);

  return (
    <>
      <InfoTooltip
        iconSize="small"
        heading="Variable Amount Billed Over"
        maxWidth={450}
        placement="right-start"
        content={contentInfo}
        variant={'plain'}
      />
    </>
  );
};

export default memo(InfoTooltipBlueprintVabo);
