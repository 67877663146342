/* eslint-disable complexity */

import React, { memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormHelperText, SelectChangeEvent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { SerializedError } from '@reduxjs/toolkit';
import {
  AdapterDateFns,
  Autocomplete,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Container,
  DatePicker,
  Hero,
  Link,
  LocalizationProvider,
  Radio,
  Select,
  Spinner,
  Stack,
  Typography,
  useSnackbar,
  useForm,
  Grid,
  Controller,
  MonetaryField,
} from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import { ErrorReturn } from 'api/crudGraphQL';
import { useGetAuditsQuery } from 'api/crudGraphQL/audits/getAudits';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { useGetLeadSourcesQuery } from 'api/crudGraphQL/lead_sources/getLeadSources';
import { useGetNovaOpportunitiesQuery } from 'api/crudGraphQL/opportunities/getNovaOpportunities';
import { useGetPartnersQuery } from 'api/crudGraphQL/partners/getPartners';
import { useGetPillarsQuery } from 'api/crudGraphQL/pillars/getPillars';
import { useLazyGetSalesforceAccountsQuery } from 'api/crudGraphQL/salesforce_opportunities/getSalesforceAccounts';
import { useLazyGetSalesforceOpportunitiesQuery } from 'api/crudGraphQL/salesforce_opportunities/getSalesforceOpportunities';
import { useCreateStrategyMutation } from 'api/crudGraphQL/strategies/createStrategy';
import { useGetUsersQuery } from 'api/crudGraphQL/users/getUsers';
import { useGetVaboTypesQuery } from 'api/crudGraphQL/vabo_types/getVaboTypes';
import { novaGraphQLClient } from 'api/entityGraphQL/NovaGraphQLClient';
import { AppDispatch } from 'app/store';
import { AccessControl } from 'components/AccessControl/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { StringParam, useQueryParam } from 'use-query-params';
import { formatLocalDateToMMDDYYYY } from 'utils/date/dateFormatter';
import { filterOptions } from 'utils/form/mui-autocomplete/filterOptions';
import { valueExists } from 'utils/form/valueExists';
import formatErrorToHumanReadable from 'utils/format/formatErrorToHumanReadable';
import { compareByName } from 'utils/sort/sortingUtil';
import { Audit } from 'features/entitiesRedux';
import { Client, updateClient } from 'features/entitiesRedux/models/client';
import { Opportunity } from 'features/entitiesRedux/models/opportunity';
import { Partner } from 'features/entitiesRedux/models/partner';
import { SalesforceAccount } from 'features/entitiesRedux/models/salesforce';
import { Strategy, createKickoffDeck, StrategyVaboTypes } from 'features/entitiesRedux/models/strategy';
import { LeadSource, Pillar, VaboType } from 'features/entitiesRedux/models/types';
import { useAccount } from 'features/global/hooks';
import { PricingVersion } from 'features/library/constants';
import { CreatePackageBlueprintFormType } from './CreatePackageBasedBlueprintFormDataType';
import { getNovaOpportunitiesProjection, salesforceOpportunityProjection } from './projection';
import { LABELS_TYPES } from '../../../utils';
import SalesforceOpportunityLinkModal from '../components/SalesforceOpportunityLinkModal';
import { PILLAR_SLUGS_TO_DEFAULT_LEAD_SOURCE_TO_NON_APPLICABLE } from '../constants';
import InfoTooltipBlueprintVabo from '../ViewBlueprintPage/components/InfoTooltipBlueprintVabo';
import { SelectSalesforceAccount, SelectSalesforceOpportunity } from '../ViewBlueprintPage/components/Overview/components/ContractDetails/components';
import { SalesforceValuesObjectType } from '../ViewBlueprintPage/components/Overview/components/ContractDetails/components/ContractDetailsEdit/ContractDetailsEdit';

const CreatePackageBasedBlueprint = (): JSX.Element => {
  const { addSnackbar } = useSnackbar();

  const { data: novaOpportunities } = useGetNovaOpportunitiesQuery({
    limit: -1, // get all opportunities
    projection: getNovaOpportunitiesProjection
  });

  const defaultValuesEmpty = {
    audit_id: undefined,
    client_id: undefined,
    commission_flat_fee: '0', // string because we are using a monetary field for this, which is a field type text
    date: '',
    guaranteed_term: undefined,
    is_vabo: false,
    lead_source_employee_id: undefined,
    lead_source_id: undefined,
    length: undefined,
    partner_id: undefined,
    pillar_id: undefined,
    pricing_version: PricingVersion.PACKAGE,
    status: 'open',
    type: undefined,
    vabo_type_id: undefined,
  };

  const { control, getValues, handleSubmit, setValue, watch } = useForm<CreatePackageBlueprintFormType>({
    defaultValues: defaultValuesEmpty,
    shouldUnregister: true,
  });

  /**
   * Form values from react-hook-form
   */
  const blueprintFormData = getValues();
  const [clientSelected, setClientSelected] = useState<Partial<Client>>();
  const [leadSourceSelected, setLeadSourceSelected] = useState<LeadSource>();
  const [pillarSelected, setPillarSelected] = useState<Pillar>();
  const [appraisalSelected, setAppraisalSelected] = useState<Audit>();

  const [salesforceOpportunityLinkModalIsOpen, toggleSalesforceOpportunityLinkModal] = useReducer((open) => !open, false);
  const [alreadyLinkedSelectedOpportunity, setAlreadyLinkedSelectedOpportunity] = useState<Opportunity | undefined>();
  const [salesforceValuesObject, setSalesforceValuesObject] = useState<SalesforceValuesObjectType>({
    salesforce_opportunity_name: null,
    salesforce_opportunity_id: null,
    salesforce_client_id: clientSelected?.salesforce_client_id,
    salesforce_client_name: clientSelected?.salesforce_client_name,
    salesforce_stage: null,
  });


  /**
   * Function that returns the opportunity if it is already linked to another blueprint
   */
  const findLinkedOpportunity = useCallback((): Opportunity | undefined => {
    return novaOpportunities?.data?.find((opportunity) => opportunity?.strategy ? opportunity?.salesforce_id === salesforceValuesObject.salesforce_opportunity_id && opportunity?.strategy?.status !== 'won' && opportunity?.strategy?.status !== 'lost' : undefined );
  }, [novaOpportunities?.data, salesforceValuesObject.salesforce_opportunity_id]);

  /**
   * Variable that returns the opportunity if it is already linked to another blueprint
   * and opens the modal if it is not already open
   *
   * @returns {Opportunity | undefined} opportunity
   */
  const getAlreadyLinkedSelectedOpportunity = useCallback(() => {
    const linkedOpportunity: Opportunity | undefined = findLinkedOpportunity();
    if (!salesforceOpportunityLinkModalIsOpen) setAlreadyLinkedSelectedOpportunity(linkedOpportunity);
    return linkedOpportunity;
  }, [findLinkedOpportunity, salesforceOpportunityLinkModalIsOpen]);

  useEffect(() => {
    if (novaOpportunities) {
      getAlreadyLinkedSelectedOpportunity();
    }
  }, [getAlreadyLinkedSelectedOpportunity, novaOpportunities]);

  useEffect(() => {
    if (alreadyLinkedSelectedOpportunity) {
      toggleSalesforceOpportunityLinkModal();
      setSalesforceValuesObject(previousValues => ({
        salesforce_opportunity_name: null,
        salesforce_opportunity_id: null,
        salesforce_client_id: previousValues.salesforce_client_id,
        salesforce_stage: null,
      }));
    }
  }, [alreadyLinkedSelectedOpportunity]);

  /**
   * Clear salesforce stage when salesforce opp is cleared
   */
  useEffect(() => {
    if (!salesforceValuesObject.salesforce_opportunity_id) {
      setSalesforceValuesObject(previousValues => ({
        ...previousValues,
        salesforce_stage: null,
      }));
    }
  }, [salesforceValuesObject.salesforce_opportunity_id]);

  // Reset guaranteed term if it's greater than the contract length
  useEffect(() => {
    if (blueprintFormData.guaranteed_term && blueprintFormData.length && blueprintFormData.guaranteed_term > blueprintFormData.length) {
      setValue('guaranteed_term', undefined);
    }
  }, [blueprintFormData.guaranteed_term, blueprintFormData.length, getValues, setValue]);

  /**
   * For an unknown reason in this file, the AutoComplete dropdowns are having padding added to them
   * from what appears to be the emotion css library
   *
   * This is a workaround to remove the extra padding
   */
  const autoCompletePaddingStyles = useMemo(() => {
    return {
      '& .MuiOutlinedInput-root': {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    };
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const [salesforceAccounts, setSalesforceAccounts] = useState<Partial<SalesforceAccount>[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    data: employees,
    isFetching: isFetchingEmployees,
    error: errorFetchingEmployees
  } = useGetUsersQuery({
    limit: 9999
  });

  const isLeadSourcePeople = leadSourceSelected?.slug === 'people';

  const [creationType] = useQueryParam('creationType', StringParam);
  const [blueprintDataString] = useQueryParam('blueprintData', StringParam);
  const [appraisalIdQueryParam] = useQueryParam('appraisal_id', StringParam);

  const blueprintDataDecoded = decodeURIComponent(blueprintDataString ?? '');
  const blueprintData: Partial<Strategy> = blueprintDataDecoded && typeof (blueprintDataDecoded) === 'string' ? JSON.parse(blueprintDataDecoded) : undefined;
  let isCreatingResign = false;
  const creatingResignFromExistingBlueprint = creationType && typeof (creationType) === 'string' && creationType === 'resign' && blueprintData ? true : false;
  if (creatingResignFromExistingBlueprint || pillarSelected?.slug === 'resign') {
    isCreatingResign = true;
  }
  let isCreatingServiceExpansion = false;
  if (pillarSelected?.slug === 'service-expansion') {
    isCreatingServiceExpansion = true;
  }

  const [createStrategyRequest] = useCreateStrategyMutation();
  const [salesforceAccountLinkUpdateInProgress, setSalesforceAccountLinkUpdateInProgress] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [attemptedLoadAppraisalFormDataFromQueryParam, setAttemptedLoadAppraisalFormDataFromQueryParam] = useState<boolean>(false);
  const [alreadySetResignFormData, setAlreadySetResignFormData] = useState<boolean>(false);

  /**
   * For now this is the max users expect to have but this may change in the future
   */
  const MAX_CONTRACT_LENGTH = 24;
  const INVALID_DATE = 'Invalid Date';

  const { data: pillars = [], isFetching: isFetchingPillars, error: errorFetchingPillars } = useGetPillarsQuery({
    projection: { id: true, name: true, slug: true }
  });

  const { data: lead_sources = [], isFetching: isFetchingLeadSources, error: errorFetchingLeadSources } = useGetLeadSourcesQuery({
    pricing_version: PricingVersion.PACKAGE,
    projection: { id: true, name: true, slug: true, description: true }
  });

  const { data: vabo_types = [], isFetching: isFetchingVaboTypes, error: errorFetchingVaboTypes } = useGetVaboTypesQuery({
    pricing_version: PricingVersion.PACKAGE,
    projection: StrategyVaboTypes,
  });

  /**
   * Set the resign form values when the resign object is available from navigating from the resign dialog
   * This is only called when the user is creating a resign
   */
  const setResignFormValuesOnLoad = useCallback(() => {
    if (blueprintData) {
      const pillarResign = pillars.find(pillar => pillar?.slug === 'resign');
      const leadSourceNotApplicable = lead_sources.find(lead_source => lead_source?.slug === 'not-applicable');
      setValue('client_id', blueprintData.client?.id);
      setValue('audit_id', blueprintData.audit?.id);
      setValue('pillar_id', pillarResign?.id);
      setValue('type', blueprintData?.type);
      setValue('lead_source_id', leadSourceNotApplicable?.id);
      setValue('length', blueprintData.length);
      setValue('lead_source_employee_id', blueprintData?.lead_source_employee ? blueprintData?.lead_source_employee.id : undefined);
      setValue('partner_id', blueprintData?.partner?.id);
      setClientSelected(blueprintData.client);
      setAppraisalSelected(blueprintData.audit);
      setPillarSelected(pillarResign);
      setLeadSourceSelected(leadSourceNotApplicable);
    }
  }, [blueprintData, lead_sources, pillars, setValue]);

  const haveLoadedPillars = !isFetchingPillars && pillars.length > 0;
  const haveLoadedLeadSources = !isFetchingLeadSources && lead_sources.length > 0;

  useEffect(() => {
    if (isCreatingResign && !alreadySetResignFormData && haveLoadedPillars && haveLoadedLeadSources) {
      setResignFormValuesOnLoad();
      setAlreadySetResignFormData(true);
    }
  }, [alreadySetResignFormData, haveLoadedLeadSources, haveLoadedPillars, isCreatingResign, setResignFormValuesOnLoad]);

  /**
   * Should skip fetching appraisals if the client is not selected and there is no appraisal id query param
   * If one is available, update the form data with the appraisal from the query param
   */
  const shouldSkipFetchingAppraisals = !blueprintFormData.client_id && !appraisalIdQueryParam;

  const { data: clients, isFetching: isFetchingClients, error: errorFetchingClients, refetch: refetchClients } = useGetClientsQuery({
    limit: 9999,
    projection: {
      id: true,
      name: true,
      salesforce_client_id: true,
      salesforce_client_name: true,
      business_type: {
        id: true,
        name: true,
        slug: true,
      },
    }
  });

  const { data: appraisals, isFetching: isFetchingAppraisals } = useGetAuditsQuery({
    limit: 9999,
    client_id: clientSelected?.id,
    projection: {
      id: true,
      name: true,
      client: {
        id: true,
        name: true
      }
    }
  }, {
    skip: shouldSkipFetchingAppraisals
  });

  const updateFormDataWithAppraisalFromQueryParam = useCallback(() => {
    if(appraisalIdQueryParam) {
      const appraisalFound = appraisals?.data?.find(appraisal => appraisal.id === parseInt(appraisalIdQueryParam));
      if(appraisalFound && appraisalFound.client && clients) {
        const clientLinkedToAppraisal = clients?.data?.find(client => client.id === appraisalFound.client?.id);
        if (clientLinkedToAppraisal) {
          setClientSelected(clientLinkedToAppraisal);
          setAppraisalSelected(appraisalFound);
          setValue('client_id', clientLinkedToAppraisal.id);
          setValue('audit_id', appraisalFound.id);
        }
        else {
          setAppraisalSelected(appraisalFound);
          setValue('audit_id', appraisalFound.id);
        }
      }
    }
    // even if the appraisal id query param is not found, set the flag to true so we don't keep checking for it and trying to set it
    setAttemptedLoadAppraisalFormDataFromQueryParam(true);
  }, [appraisalIdQueryParam, appraisals?.data, clients, setValue]);

  if(appraisals && !attemptedLoadAppraisalFormDataFromQueryParam) {
    updateFormDataWithAppraisalFromQueryParam();
  }

  const { data: partners, isFetching: isFetchingPartners, error: errorFetchingPartners } = useGetPartnersQuery({
    projection: {
      id: true,
      name: true,
      percent: true,
      email: true,
      phone: true,
      company: true,
    },
    limit: 9999,
    sort: ['company']
  });

  const [fetchSalesforceOpportunities, { isFetching: salesforceOpportunitiesFetching }] = useLazyGetSalesforceOpportunitiesQuery();
  const [fetchSalesforceAccounts, { isFetching: salesforceAccountsFetching }] = useLazyGetSalesforceAccountsQuery();

  const errorSnackbar = useCallback((error: ErrorReturn | SerializedError | undefined, message: string): void => {
    if (error) {
      addSnackbar({
        variant: 'error',
        message: message,
      });
      console.error(message, error);
    }
  }, [addSnackbar]);

  /**
   * Log errors and notify user if fetch calls fail
   */
  useEffect(() => {
    errorSnackbar(errorFetchingPillars, 'Error fetching pillars');
    errorSnackbar(errorFetchingClients, 'Error fetching clients');
    errorSnackbar(errorFetchingLeadSources, 'Error fetching lead sources');
    errorSnackbar(errorFetchingEmployees, 'Error fetching employees');
    errorSnackbar(errorFetchingPartners, 'Error fetching partners');
    errorSnackbar(errorFetchingVaboTypes, 'Error fetching vabo types');
  }, [errorFetchingClients, errorFetchingEmployees, errorFetchingLeadSources, errorFetchingPartners, errorFetchingPillars, errorFetchingVaboTypes, errorSnackbar]);

  const getSalesforceAccounts = useCallback(async () => {
    fetchSalesforceAccounts({
      limit: 9999
    }).unwrap()
      .then((salesforceAccountsData: SalesforceAccount[]) => {
        setSalesforceAccounts(salesforceAccountsData);
      }).catch((error) => {
        const errorMessage = 'Error fetching salesforce accounts.';
        addSnackbar({
          variant: 'error',
          message: errorMessage,
        });
        console.error(errorMessage, error);
      });
  }, [addSnackbar, fetchSalesforceAccounts]);

  const getSalesforceOpportunities = useCallback(async (accountId: string) => {
    fetchSalesforceOpportunities({
      account_id: accountId,
      projection: salesforceOpportunityProjection
    }).unwrap().catch((error) => {
      const errorMessage = 'Error fetching salesforce opportunities.';
      addSnackbar({
        variant: 'error',
        message: errorMessage,
      });
      console.error(errorMessage, error);
    });
  }, [addSnackbar, fetchSalesforceOpportunities]);

  /**
   * Fetch salesforce accounts and opportunities when client is selected
   */
  useEffect(() => {
    if (clientSelected) {
      getSalesforceAccounts();
      if (clientSelected.salesforce_client_id) {
        getSalesforceOpportunities(clientSelected.salesforce_client_id);
      }
    }
  }, [clientSelected, getSalesforceAccounts, getSalesforceOpportunities]);

  const trackBlueprintCreatedEventInMixpanel = useCallback((strategy: Strategy, blueprintName: string) => {
    // track "Blueprint created" event
    try {
      const options = {
        name: blueprintName,
        blueprintId: strategy.id,
        clientId: blueprintFormData.client_id,
        clientName: clientSelected?.name,
        businessType: clientSelected?.business_type?.name,
        businessTypeId: clientSelected?.business_type?.id,
        businessTypeSlug: clientSelected?.business_type?.slug,
        appraisalId: blueprintFormData?.audit_id,
        pillar: pillarSelected?.name,
        is_vabo: blueprintFormData?.is_vabo,
        vaboTypeId: blueprintFormData?.vabo_type_id,
      };
      console.log('Track: Blueprint created', {
        options,
      });
      if (mixpanel?.track) {
        mixpanel.track('Blueprint created', options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }

  }, [blueprintFormData.client_id, blueprintFormData?.audit_id, blueprintFormData?.is_vabo, blueprintFormData?.vabo_type_id, clientSelected?.name, clientSelected?.business_type?.name, clientSelected?.business_type?.id, clientSelected?.business_type?.slug, pillarSelected?.name, mixpanel]);

  const callCreateKickoffDeck = useCallback(async (strategy: Strategy) => {
    try {
      const createKickoffAction: any = await dispatch(createKickoffDeck({ strategy_id: strategy.id }));
      if (createKickoffDeck.fulfilled.match(createKickoffAction)) {
        addSnackbar({
          variant: 'success',
          message: 'Created Kickoff Deck',
        });
      }
      if (createKickoffDeck.rejected.match(createKickoffAction)) {
        const needsReauth = createKickoffAction?.error?.message?.includes('Google Account is missing');
        console.log('Error creating kickoff deck');
        addSnackbar({
          variant: 'error',
          message: `An error occurred while creating Kickoff Deck${needsReauth ? '. Please reauthenticate your Google account.' : ''}`,
        });
      }
    } catch (error) {
      console.error('Error creating kickoff deck', error);
    }
  }, [addSnackbar, dispatch]);

  const isFormValid = useCallback(() => {
    const fieldsToValidate = [
      {
        name: 'pillar_id',
        value: blueprintFormData?.pillar_id
      },
      {
        name: 'date',
        value: blueprintFormData?.date
      },
      {
        name: 'length',
        value: blueprintFormData?.length
      },
      {
        name: 'type',
        value: blueprintFormData?.type
      },
      {
        name: 'lead_source_id',
        value: blueprintFormData?.lead_source_id
      },
      {
        name: 'client_id',
        value: blueprintFormData?.client_id
      },
      {
        name: 'guaranteed_term',
        value: blueprintFormData?.guaranteed_term
      },
      {
        name: 'is_vabo',
        value: blueprintFormData?.is_vabo
      }
    ];

    const valueEnteredForAllFields = fieldsToValidate.every((field) => {
      if(field.name === 'is_vabo' && field.value === true) {
        // if is_vabo is true, then vaboTypeId is required, otherwise just make sure is_vabo has a value
        return valueExists(blueprintFormData?.vabo_type_id);
      }
      return valueExists(field.value);
    });

    if (!valueEnteredForAllFields || blueprintFormData?.date === INVALID_DATE
      || blueprintFormData.date === undefined) {
      return false;
    }

    /**
     * If type of lead is channel partner enforce user selecting a partner referral
     * If the type of lead is people, enforce user selecting a employee referral
     */
    if ((leadSourceSelected?.slug === 'channel-partner' && !blueprintFormData?.partner_id) || (leadSourceSelected?.slug === 'people' && !blueprintFormData?.lead_source_employee_id)
    ) {
      return false;
    }
    return true;

  }, [blueprintFormData?.pillar_id, blueprintFormData.date, blueprintFormData?.length, blueprintFormData?.type, blueprintFormData?.lead_source_id, blueprintFormData?.client_id, blueprintFormData?.guaranteed_term, blueprintFormData?.is_vabo, blueprintFormData?.partner_id, blueprintFormData?.lead_source_employee_id, blueprintFormData?.vabo_type_id, leadSourceSelected?.slug]);

  const updateSalesforceAccountLink = useCallback(async (unlinking: boolean, salesforceAccount: Partial<SalesforceAccount> | undefined = undefined) => {
    if (clientSelected && clientSelected.id) {
      setSalesforceAccountLinkUpdateInProgress(true);
      const salesforceAccountId = salesforceAccount?.id ?? null;
      const salesforceAccountName = salesforceAccount?.name ?? null;

      const action = await dispatch(
        updateClient({
          id: clientSelected.id,
          // if unlinking, clear the salesforce client information, otherwise set it
          salesforce_client_id: unlinking ? null : salesforceAccountId,
          salesforce_client_name: unlinking ? null : salesforceAccountName
        })
      );
      setSalesforceAccountLinkUpdateInProgress(false);

      const successMessageVerb = unlinking ? 'unlinked' : 'linked';
      const toOrFrom = unlinking ? 'from' : 'to';

      if (updateClient.fulfilled.match(action)) {
        const oldSalesForceAccountNameToDisplay = clientSelected?.salesforce_client_name;
        const salesForceAccountNameToDisplay = unlinking ? oldSalesForceAccountNameToDisplay : salesforceAccountName;
        addSnackbar({
          variant: 'success',
          message: `${clientSelected.name} was ${successMessageVerb} ${toOrFrom} Salesforce Account: '${salesForceAccountNameToDisplay}' successfully`,
        });

        if (unlinking) {
          setSalesforceValuesObject({
            salesforce_client_id: undefined,
            salesforce_client_name: undefined,
            salesforce_opportunity_id: undefined,
            salesforce_opportunity_name: undefined,
          });
        } else {
          setSalesforceValuesObject({
            salesforce_client_id: salesforceAccountId,
            salesforce_client_name: salesforceAccountName,
          });
        }
      }

      if (updateClient.rejected.match(action)) {
        // Slice out the actual error message from the stringified error response
        const errorMessage = typeof action?.error?.message === 'string'
          ? formatErrorToHumanReadable(action.error.message)
          : 'An unknown error occurred. Please try again.';

        addSnackbar({
          variant: 'error',
          message: errorMessage,
        });

        /**
         * Clear the form data of the salesforce account, opportunity, and client salesforce information
         * because the update failed
         */
        setSalesforceValuesObject({
          salesforce_client_id: undefined,
          salesforce_client_name: undefined,
          salesforce_opportunity_id: undefined,
          salesforce_opportunity_name: undefined,
        });
      }
      /**
       * Refetch the clients data to get the latest salesforce client information per client
       */
      refetchClients();
    }
  }, [addSnackbar, clientSelected, dispatch, refetchClients]);

  const submitForm = useCallback(async () => {
    setIsSubmitting(true);
    setSubmitClicked(true);

    if (isFormValid()) {
      try {
        if (blueprintFormData && blueprintFormData.client_id) {
          const {
            audit_id,
            client_id,
            commission_flat_fee,
            date,
            guaranteed_term,
            is_vabo,
            lead_source_employee_id,
            lead_source_id,
            length,
            partner_id,
            pillar_id,
            status,
            type,
            vabo_type_id
          } = blueprintFormData;
          /**
           * Need this for sending to mixpanel but since it is a derived field from other fields that can change independently,
           * we don't need to send it to the backend. It can be derived later from the other fields
           */
          const blueprintName = clientSelected?.name + ' ' + format(new Date(date ?? ''), 'MM/dd/yyyy') + ' ' + pillarSelected?.name;
          const strategy = await createStrategyRequest({
            audit_id,
            author_id: account?.id,
            client_id,
            commission_flat_fee: commission_flat_fee ? parseFloat(commission_flat_fee) : undefined,
            date: format(new Date(date ?? ''), 'yyyy-MM-dd'),
            guaranteed_term,
            is_vabo,
            lead_source_employee_id,
            lead_source_id: lead_source_id ?? undefined,
            length,
            partner_id,
            pillar_id,
            pricing_version: PricingVersion.PACKAGE,
            salesforce_opportunity_id: salesforceValuesObject.salesforce_opportunity_id,
            salesforce_stage: salesforceValuesObject?.salesforce_stage,
            status,
            type,
            vabo_type_id
          }).unwrap();

          trackBlueprintCreatedEventInMixpanel(strategy, blueprintName);

          /**
         * Only create new kickoff deck if user has authorized Google account
         */
          if (account?.accounts?.filter((account: { type: string; }) => account?.type === 'google')?.length) {
            await callCreateKickoffDeck(strategy);
          }

          // if audit_id is present, then update audit with strategy id so they are linked
          if (appraisalSelected && appraisalSelected.id && strategy.id) {
            await novaGraphQLClient.updateAudit({
              id: appraisalSelected.id,
              strategy_id: strategy.id
            });
          }

          addSnackbar({
            variant: 'success',
            message: 'Blueprint created!',
          });

          history.push(`/blueprints/package/${strategy.id}`, { from: 'internal' });
        }

      } catch (error) {
        const errorMessage = 'Error creating Blueprint';
        addSnackbar({
          variant: 'error',
          message: errorMessage,
        });
        console.error(errorMessage, error);
      }
    }
    setIsSubmitting(false);
  }, [account?.accounts, account?.id, addSnackbar, appraisalSelected, blueprintFormData, callCreateKickoffDeck, clientSelected?.name, createStrategyRequest, history, isFormValid, pillarSelected?.name, salesforceValuesObject.salesforce_opportunity_id, salesforceValuesObject?.salesforce_stage, trackBlueprintCreatedEventInMixpanel]);

  /**
   * Default the lead source to not applicable when the pillar is resign, service expansion, rollover, or addendum
   * and set state to disable the lead source dropdown
   *
   * @param pillarSlug
   * @returns LeadSource | undefined
   */
  const getDefaultLeadSourceToNonApplicableFromPillar = useCallback((pillarSlug: string | undefined) : LeadSource | undefined => {
    if (pillarSlug && PILLAR_SLUGS_TO_DEFAULT_LEAD_SOURCE_TO_NON_APPLICABLE.includes(pillarSlug)) {
      const nonApplicableLeadSource = lead_sources.find(lead_source => lead_source.slug === 'not-applicable');
      if (nonApplicableLeadSource) {
        return nonApplicableLeadSource;
      }
      return undefined;
    }
    return undefined;
  }, [lead_sources]);

  /**
   * Default the lead source to not applicable when the pillar is resign, service expansion, rollover, or addendum
   */
  useEffect(() => {
    if (blueprintFormData.pillar_id) {
      const defaultLeadSource = getDefaultLeadSourceToNonApplicableFromPillar(pillarSelected?.slug);
      if (defaultLeadSource) {
        setLeadSourceSelected(defaultLeadSource);
        setValue('lead_source_id', defaultLeadSource.id);
      }
    }
  }, [getDefaultLeadSourceToNonApplicableFromPillar, pillarSelected, blueprintFormData.pillar_id, setValue]);

  /**
   * Return the partner referral text based on what data is available
   *
   * @param partner
   * @returns string - partner referral name based on what data is available
   */
  const formatPartnerReferralName = useCallback((partner: Partial<Partner>) => {
    let text = `Partner #${partner.id}`;
    if (partner.company && partner.name) {
      text = `${partner.company} - ${partner.name}`;
    }
    if (!partner.company && partner.name) {
      text = partner.name;
    }
    if (partner.company && !partner.name) {
      text = partner.company;
    }
    return text;
  }, []);

  /**
     * Because not all partners have a name or company,
     * we need to choose the format of the partner referral name based on what data is available
     *
     * @return string[] partner referral names or empty array if no partners are available
     */
  const getPartnerReferralOptions = useCallback(() => {
    return partners && partners.data ? partners?.data?.map((partner) => {
      const partnerReferralName = formatPartnerReferralName(partner);
      return {
        id: partner.id,
        key: `${partnerReferralName}-${partner.id}`,
        value: partnerReferralName,
        label: partnerReferralName
      };
    }) : [];
  }, [formatPartnerReferralName, partners]);

  const partnerReferralDropdownOptions = useMemo(() => getPartnerReferralOptions(), [getPartnerReferralOptions]);

  const pillarOptions = useMemo(() => [...pillars].filter((pillar) => {
    return pillar.slug === 'new-business' || pillar.slug === 'service-expansion' || pillar.slug === 'resign';
  }).sort(compareByName).map((pillar: Pillar) => (
    <Select.Item key={`pillar${pillar.id}`} value={pillar.id}>{pillar.name}</Select.Item>
  )), [pillars]);

  // sort lead sources alphabetically by name without mutating original array
  const sortedLeadSources = useMemo(() => {
    let newSortedLeadSources: LeadSource[] = [...lead_sources];
    if(isCreatingResign || isCreatingServiceExpansion) {
      const resignLeadSourceSlugs = ['channel-partner', 'not-applicable'];
      newSortedLeadSources = newSortedLeadSources.filter(leadSource => resignLeadSourceSlugs.includes(leadSource.slug));
    }
    newSortedLeadSources.sort(compareByName);
    return newSortedLeadSources;
  }, [isCreatingResign, lead_sources, isCreatingServiceExpansion]);


  /**
   * Return true if the start date and length of contract are valid
   *
   * @returns boolean true if the start date and length of contract are valid, false otherwise
   */
  const startDateAndLengthOfContractValid = useMemo(() => {
    return blueprintFormData?.length && blueprintFormData?.date && blueprintFormData?.date != INVALID_DATE;
  }, [blueprintFormData?.date, blueprintFormData?.length]);

  const endDate = useMemo(() => {
    if (startDateAndLengthOfContractValid && blueprintFormData?.length && blueprintFormData?.date) {
      const startDate = new Date(blueprintFormData.date ?? '');
      startDate.setMonth(startDate.getMonth() + (blueprintFormData.length ?? 0));
      startDate.setDate(startDate.getDate() - 1);
      return startDate;
    }
    return null;
  }, [blueprintFormData, startDateAndLengthOfContractValid]);

  const guaranteedTermEndDate = useMemo(() => {
    if (startDateAndLengthOfContractValid && blueprintFormData?.guaranteed_term && blueprintFormData?.date) {
      const startDate = new Date(blueprintFormData.date ?? '');
      startDate.setMonth(startDate.getMonth() + (blueprintFormData.guaranteed_term ?? 0));
      startDate.setDate(startDate.getDate() - 1);
      return startDate;
    }
    return null;
  }, [startDateAndLengthOfContractValid, blueprintFormData.guaranteed_term, blueprintFormData?.date]);

  const salesforceAccountButtonText = useMemo(() => {
    if (salesforceAccountLinkUpdateInProgress) {
      return `Updating ${LABELS_TYPES.CAPITALIZE_CLIENT}...`;
    }
    return 'Remove Salesforce Account';

  }, [salesforceAccountLinkUpdateInProgress]);

  const startDateToShow: Date | null = useMemo(() => blueprintFormData.date ? new Date(blueprintFormData.date) : null, [blueprintFormData.date]);
  const hasSalesForceAccount = useMemo(() => salesforceValuesObject.salesforce_client_id || salesforceValuesObject.salesforce_client_name, [salesforceValuesObject.salesforce_client_id, salesforceValuesObject.salesforce_client_name]);

  const typeOfLeadDropdownWidth = useMemo(() => {
    if (isLeadSourcePeople) {
      return '50%';
    }
    return '100%';
  }, [isLeadSourcePeople]);

  const getHeroLinkText = useCallback((blueprintData: Partial<Strategy>) => {
    let linkText = '';

    if (blueprintData.client) {
      linkText = blueprintData.client.name;
    }
    if (blueprintData.date) {
      linkText = linkText + ' ' + formatLocalDateToMMDDYYYY(blueprintData.date); // format date to short date like 1/1/2021
    }
    if (blueprintData.pillar) {
      linkText = linkText + ' ' + blueprintData.pillar.name;
    }
    if (blueprintData.version_name) {
      linkText = linkText + ' - ' + blueprintData.version_name.toUpperCase();
    }

    return linkText;
  }, []);

  const leadSourceShouldBeDisabled = blueprintFormData.pillar_id && pillarSelected?.slug && PILLAR_SLUGS_TO_DEFAULT_LEAD_SOURCE_TO_NON_APPLICABLE.includes(pillarSelected.slug) ? true : false;

  const typeOfLeadMenuWidth = isLeadSourcePeople ? '316px' : '516px';
  let heroDescription = <>Create a new Blueprint using packaged pricing.</>;
  if (isCreatingResign && blueprintData) {
    const heroLink = <Link href={`/blueprints/package/${blueprintData.id}`}>{getHeroLinkText(blueprintData)}</Link>;
    heroDescription = <>Re-sign generated from {heroLink} </>;
  }

  const getLeadSource = useCallback((lead_source_id: number | undefined) => {
    return sortedLeadSources.find((lead_source: LeadSource) => lead_source.id === lead_source_id);
  }, [sortedLeadSources]);

  const getPillar = useCallback((pillar_id: number | undefined) => {
    return pillars.find((pillar: Pillar) => pillar.id === pillar_id);
  }, [pillars]);

  const getClient = useCallback((client_id: number | undefined) => {
    return clients?.data?.find((client: Client) => client.id === client_id);
  }, [clients]);

  const getAppraisal = useCallback((appraisal_id: number | undefined) => {
    return appraisals?.data?.find((appraisal: Audit) => appraisal.id === appraisal_id);
  }, [appraisals]);

  const helperTextStartDate = (): string | undefined => {
    if (submitClicked) {
      if (!blueprintFormData.length) {
        return 'Please select a contract length first';
      } else if (!blueprintFormData.date) {
        return 'Please select a start date';
      }
    }
  };

  const helperTextGuaranteedTerm = (): string | undefined => {
    if (submitClicked) {
      if (!blueprintFormData.length) {
        return 'Please select a contract length first';
      } else if (!blueprintFormData.guaranteed_term) {
        return 'Please select a guaranteed term';
      }
    }
  };

  /**
   * Formatted months guaranteed options:
   * the max guaranteed term is dependent on the contract length
   */
  const monthsGuaranteed = useMemo(() => {
    if (blueprintFormData.length) {
      return Array(blueprintFormData.length).fill(0).map((_, i) => i + 1);
    }
    return [];
  }, [blueprintFormData.length]);

  /**
   * Formatted contract length options:
   * Max 24 months
   */
  const monthsContract = useMemo(() => {
    return Array(MAX_CONTRACT_LENGTH).fill(0).map((_, i) => i + 1);
  }, []);

  /**
   * Render the additional fields when lead source is channel partner
   * - Partner Referral
   * - Flat Fee Commission
   *
   */
  const renderFieldsChannelPartner = useCallback(() => {
    // only show the partner referral dropdown if the lead source is channel partner
    if (leadSourceSelected?.slug === 'channel-partner') {
      return (
        <>
          <Grid item xs={6}>
            <Controller
              control={control}
              name='partner_id'
              rules={{
                required: 'Please select a partner',
              }}
              defaultValue={blueprintFormData.partner_id}
              render={({ field: { onChange, value, ...field }, fieldState: { error }}): JSX.Element => {
                return (
                  <Autocomplete
                    {...field}
                    id='partnerReferralDropdown'
                    error={submitClicked && !value ? error?.message : ''}
                    filterOptions={filterOptions}
                    isOptionEqualToValue={(option, value): boolean => option.id === value?.id }
                    label='Partner Referral'
                    labelKey='value'
                    loading={isFetchingPartners}
                    onChange={(event, newValue) => onChange(newValue?.id)}
                    options={partnerReferralDropdownOptions}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name='commission_flat_fee'
              defaultValue={blueprintFormData.commission_flat_fee}
              render={({ field: { onChange, value }}): JSX.Element => {
                return (
                  <MonetaryField
                    id="commission-flat-flee-id"
                    label="Flat Fee Commission"
                    helperText="Flat fee paid out for Partner Referral in addition to partner's commission payment"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                  />
                );
              }}
            />
          </Grid>
        </>
      );
    }
  }, [blueprintFormData.commission_flat_fee, blueprintFormData.partner_id, control, isFetchingPartners, leadSourceSelected?.slug, partnerReferralDropdownOptions, submitClicked]);

  return (
    <AccessControl
      action={[Action.create]}
      resource={Resource.packageStrategy}
    >
      <>
        <Hero
          breadcrumbs={<Breadcrumbs items={[{ name: 'Home', path: '/' }, { name: 'Blueprints (Package)', path: '/blueprints/package' }, { name: 'New Blueprint', path: '/create-package-based-blueprint' }]} />}
          description={heroDescription}
          title="New Blueprint"
        />
        <form onSubmit={handleSubmit(submitForm)}>
          <Container hasVerticalPadding>
            <Card
              header={
                <CardHeader
                  title="Contract Details"
                />
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='client_id'
                    rules={{
                      required: 'Please select a client',
                    }}
                    defaultValue={blueprintFormData.client_id}
                    render={({ field: { onChange, value, ...field }, fieldState: { error }}): JSX.Element => {
                      return (
                        <Autocomplete
                          {...field}
                          key={`client-${value}`}
                          id="clientsDropdown"
                          disabled={creatingResignFromExistingBlueprint}
                          error={submitClicked && !value ? error?.message : ''}
                          filterOptions={filterOptions}
                          isOptionEqualToValue={(option, value): boolean => option.id === value?.id }
                          inputValue={clientSelected?.name}
                          label="Client"
                          labelKey='name'
                          loading={isFetchingClients}
                          onChange={(event, newValue) => {
                            setClientSelected(getClient(newValue?.id));
                            // Populate salesforce values object with the client's salesforce information
                            setSalesforceValuesObject({
                              salesforce_client_id: newValue?.salesforce_client_id,
                              salesforce_client_name: newValue?.salesforce_client_name,
                            });
                            setValue('audit_id', undefined); // Reset appraisals when client changes
                            onChange(newValue?.id);
                          }}
                          options={clients ? clients.data : []}
                          sx={{ ...autoCompletePaddingStyles }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='audit_id'
                    defaultValue={blueprintFormData.audit_id}
                    render={({ field: { onChange, value, ...field }}): JSX.Element => {
                      return (
                        <Autocomplete
                          {...field}
                          id="appraisalsDropdown"
                          // only enable when client is selected and creating resign from existing blueprint
                          disabled={!blueprintFormData.client_id || creatingResignFromExistingBlueprint || isFetchingAppraisals}
                          filterOptions={filterOptions}
                          helperText="Optional"
                          isOptionEqualToValue={(option, value): boolean => option.id === value }
                          label="Appraisal"
                          labelKey='name'
                          getOptionLabel={(option) => option.name ?? option.id} // Some appraisals do not have any name
                          loading={isFetchingAppraisals}
                          onChange={(event, newValue) => {
                            setAppraisalSelected(getAppraisal(newValue?.id));
                            onChange(newValue?.id);
                          }}
                          /**
                         * For some reason, for appraisals dropdown, it is necessary otherwise it displays the id instead of the name
                         * while for clients dropdown, it is NOT...
                         */
                          value={getAppraisal(value)}
                          options={appraisals && !isFetchingAppraisals ? appraisals.data : []}
                          sx={{ ...autoCompletePaddingStyles }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row">
                    <SelectSalesforceAccount
                      key='select-salesforce-account'
                      client={clientSelected}
                      refetchClients={refetchClients}
                      setSalesforceAccountLinkUpdateInProgress={setSalesforceAccountLinkUpdateInProgress}
                      salesforceAccountLinkUpdateInProgress={salesforceAccountLinkUpdateInProgress}
                      isSalesforceAccountRemoved={!!salesforceValuesObject.salesforce_client_id}
                      isSubmitting={salesforceAccountsFetching}
                      setSalesforceValuesObject={setSalesforceValuesObject}
                      salesforceValuesObject={salesforceValuesObject}
                    />
                    {
                      // only show the remove salesforce account button if the client has a salesforce account linked
                      hasSalesForceAccount &&
                    <Button
                      onClick={(): Promise<void> => updateSalesforceAccountLink(true)}
                      disabled={salesforceAccountLinkUpdateInProgress || creatingResignFromExistingBlueprint}
                      size="large"
                      variant="tertiary"
                      sx={{ marginLeft: '24px', width: '50%', padding: '24px', height: '56px' }}
                    >
                      {salesforceAccountButtonText}
                      {salesforceAccountLinkUpdateInProgress && <CircularProgress sx={{ marginLeft: '5px' }} size={20} />}
                    </Button>
                    }
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <SelectSalesforceOpportunity
                    salesforceClientId={clientSelected?.salesforce_client_id}
                    autoCompleteProps={{
                      disabled: !hasSalesForceAccount || creatingResignFromExistingBlueprint,
                    }}
                    isLoading={salesforceOpportunitiesFetching}
                    isSubmitting={isSubmitting}
                    salesforceAccountLinkUpdateInProgress={salesforceAccountLinkUpdateInProgress}
                    setSalesforceValuesObject={setSalesforceValuesObject}
                    salesforceValuesObject={salesforceValuesObject}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    rules={{
                      required: 'Please select a type of contract',
                    }}
                    defaultValue={blueprintFormData.type}
                    name='type'
                    render={({ field: { value, ...field }, fieldState: { error } }): JSX.Element => (
                      <Select
                        {...field}
                        id="typeOfContract"
                        disabled={creatingResignFromExistingBlueprint}
                        error={submitClicked && !value ? error?.message : ''}
                        key={`type-${value}`}
                        label="Type of Contract"
                        MenuProps={{ MenuListProps: { id: 'typeOfContractMenu' } }}
                        value={value}
                      >
                        <Select.Item value="retainer">Retainer</Select.Item>
                        <Select.Item value="project">Project</Select.Item>
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='length'
                    rules={{
                      required: 'Please select the contract length',
                    }}
                    defaultValue={blueprintFormData?.length}
                    render={({ field: { onChange, value, ...field }, fieldState: { error }}): JSX.Element => {
                      return (
                        <Select
                          {...field}
                          id='lengthOfContract'
                          error={submitClicked && !value ? error?.message : ''}
                          key={`length-${value}`}
                          label="Length of Contract"
                          MenuProps={{ MenuListProps: { id: 'lengthOfContractMenu' } }}
                          onChange={(event: SelectChangeEvent<any>): void => {
                            onChange(event.target.value);
                          }}
                          value={value}
                        >
                          {monthsContract.map((month) => (
                            <Select.Item key={`length-${month}-month`} value={month}>{pluralize('month', month, true)}</Select.Item>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='pillar_id'
                    rules={{
                      required: 'Please select a pillar',
                    }}
                    defaultValue={blueprintFormData.pillar_id}
                    render={({ field: { onChange, value, ...field }, fieldState: { error }}): JSX.Element => {
                      return (
                        <Select
                          {...field}
                          key={`pillar-${value}`}
                          id="pillarId"
                          disabled={creatingResignFromExistingBlueprint}
                          error={submitClicked && !value ? error?.message : ''}
                          label="Pillar"
                          loading={isFetchingPillars}
                          MenuProps={{ MenuListProps: { id: 'pillarIdMenu' } }}
                          onChange={(event: SelectChangeEvent<any>): void => {
                            setPillarSelected(getPillar(event.target.value));
                            setValue('partner_id', undefined);
                            setValue('lead_source_employee_id', undefined);
                            setValue('lead_source_id', undefined);
                            setLeadSourceSelected(undefined);
                            onChange(event.target.value);
                          }}
                          value={value}
                        >
                          {
                            pillarOptions
                          }
                        </Select>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack direction='row' spacing={'24px'}>
                    <Controller
                      control={control}
                      name='lead_source_id'
                      rules={{
                        required: 'Please select a lead',
                      }}
                      defaultValue={blueprintFormData.lead_source_id}
                      render={({ field: { onChange, value, ...field }, fieldState: { error }}): JSX.Element => {
                        return (
                          <Select
                            {...field}
                            key={`lead-source-${value}`}
                            id="typeOfLead"
                            error={submitClicked && !value ? error?.message : ''}
                            disabled={leadSourceShouldBeDisabled}
                            loading={isFetchingLeadSources}
                            label="Type of Lead"
                            MenuProps={{ MenuListProps: { id: 'typeOfLeadMenu' }, sx: { width: typeOfLeadMenuWidth } }}
                            onChange={(event: SelectChangeEvent<any>): void => {
                              setLeadSourceSelected(getLeadSource(event.target.value));
                              setValue('partner_id', undefined);
                              setValue('lead_source_employee_id', undefined);
                              onChange((event.target.value));
                            }}
                            // Render value is needed because the text from the options is NOT the one we want to display in the field when it's selected
                            renderValue={(): JSX.Element | undefined => {
                              return value ? <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>{getLeadSource(value)?.name}</Typography> : undefined;
                            }}
                            sx={isLeadSourcePeople ? { width: typeOfLeadDropdownWidth } : {}} // conditional styles otherwise sx prop will override styles applied to a Disabled <Select>
                            value={value}
                          >
                            {
                              sortedLeadSources.map((lead_source: LeadSource) =>
                                <Select.Item key={`leadSource${lead_source.id}`} value={lead_source.id}>
                                  <strong>{lead_source.name}</strong> ─ {lead_source.description}
                                </Select.Item>
                              )
                            }
                          </Select>
                        );
                      }}
                    />
                    {
                      // only show the employee referral dropdown if the lead source is people
                      leadSourceSelected?.slug === 'people' ?
                        <Controller
                          control={control}
                          name='lead_source_employee_id'
                          rules={{
                            required: 'Please select an employee referral',
                          }}
                          defaultValue={blueprintFormData.lead_source_employee_id}
                          render={({ field: { onChange, value, ...field }, fieldState: { error }}): JSX.Element => {
                            return (
                              <Autocomplete
                                {...field}
                                id='employeeLeadSourceDropdown'
                                error={submitClicked && !value ? error?.message : ''}
                                disabled={creatingResignFromExistingBlueprint}
                                filterOptions={filterOptions}
                                isOptionEqualToValue={(option, value): boolean => option.id === value?.id }
                                label='Employee Referral'
                                labelKey='name'
                                loading={isFetchingEmployees}
                                options={employees ? employees.data : []}
                                onChange={(event, newValue) => onChange(newValue?.id)}
                                sx={{ width: '50%', ...autoCompletePaddingStyles }}
                              />
                            );
                          }}
                        /> : <></>
                    }
                  </Stack>
                </Grid>
                {renderFieldsChannelPartner()}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='date'
                    rules={{
                      required: 'Please select a start date',
                    }}
                    defaultValue={watch('date')}
                    render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            id="start-date"
                            disabled={!watch('length')}
                            label="Start Date"
                            onChange={(startDate): void => onChange(startDate?.toString())}
                            slotProps={{
                              textField: {
                                error: error?.message ? true : false, // This is temporary until the nebula component is fixed. It should be error={error?.message}
                              },
                            }}
                            value={startDateToShow}
                          />
                          {/* Add custom helper text until the nebula component is updated and supports helper text through slotProps */}
                          <FormHelperText sx={{ marginLeft: '14px' }} error={submitClicked && !value}>{helperTextStartDate()}</FormHelperText>
                        </LocalizationProvider>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* not using end date in form, so we should refer to endDate generated from startDate for comparisons */}
                  <DatePicker id="end-date" disablePast={true} label="End Date" disabled={true} value={endDate} />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='guaranteed_term'
                    rules={{
                      required: 'Please select a guaranteed term',
                    }}
                    defaultValue={watch('guaranteed_term')}
                    render={({ field: { onChange, value, ...field } }): JSX.Element => (
                      <Select
                        {...field}
                        id="guaranteed_term"
                        defaultValue={blueprintFormData.guaranteed_term}
                        disabled={!blueprintFormData.length}
                        error={helperTextGuaranteedTerm()}
                        key={`guaranteed_term_${value}`}
                        label='Guaranteed term'
                        MenuProps={{ MenuListProps: { id: 'guaranteed_term_menu' } }}
                        onChange={(event: SelectChangeEvent<any>): void => {
                          onChange(event.target.value);
                        }}
                        sx={{ ...autoCompletePaddingStyles }}
                        value={value}
                      >
                        {monthsGuaranteed.map((month) => (
                          <Select.Item key={`guaranteed-term-${month}-month`} value={month}>{pluralize('month', month, true)}</Select.Item>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker id="guaranteed-term-end-date" label="Guaranteed Term (End Date)" disabled={true} value={guaranteedTermEndDate} />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={600} variant="caption" display="block" gutterBottom>
                      Does this client include VABO? (Ad Spend or Performance)
                    <InfoTooltipBlueprintVabo />
                  </Typography>
                  <Controller
                    control={control}
                    name='is_vabo'
                    defaultValue={watch('is_vabo')}
                    render={({ field: { value, onChange } }): JSX.Element => (
                      <Radio.Group
                        id='vaboRadioGroup'
                        aria-labelledby="vaboRadioGroup"
                        name="vaboRadioGroup"
                        value={value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
                          const isVabo = value === 'true' ? true : false; // Needed because the value argument in the onChange prop is always a string
                          onChange(isVabo);
                        }}
                        sx={{ marginTop: '8px' }}
                      >
                        <Radio value={true} label="Yes" />
                        <Radio value={false} label="No" />
                      </Radio.Group>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {blueprintFormData.is_vabo === true ? (
                    <Controller
                      control={control}
                      name={'vabo_type_id'}
                      defaultValue={blueprintFormData.vabo_type_id}
                      rules={{
                        required: 'Please select a vabo type',
                      }}
                      render={({ field: { value, onChange }, fieldState: { error }}): JSX.Element => (
                        <Select
                          id="vaboType"
                          error={submitClicked && !value ? error?.message : ''}
                          key={`vabo-type-${value}`}
                          label="VABO Type"
                          loading={isFetchingVaboTypes}
                          MenuProps={{ MenuListProps: { id: 'vaboTypeMenu' } }}
                          onChange={(event: SelectChangeEvent<any>): void => {
                            onChange((event.target.value));
                          }}
                          renderValue={(selectedVaboTypeId: any) =>
                            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>{vabo_types.find((vabo_type: VaboType) => vabo_type.id === selectedVaboTypeId)?.name}</Typography>
                          }
                          value={value}
                        >
                          {
                            vabo_types.map((vabo_type: VaboType) =>
                              <Select.Item key={`vaboType${vabo_type.id}`} value={vabo_type.id}>
                                <Typography variant="h4" marginBottom={'6px'} fontWeight={600} paragraph display={'inline'}>{vabo_type.name}</Typography> - {vabo_type.description}
                              </Select.Item>
                            )
                          }
                        </Select>
                      )}
                    />) : <></>}
                </Grid>
              </Grid>
            </Card>
            <div style={{ float: 'right', marginTop: '32px' }}>
              <Button
                sx={{ width: '102px' }}
                size="large"
                variant="secondary"
                onClick={() => history.push('/blueprints/package')}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: '24px' }}
                size="large"
                type="submit"
                variant="primary"
                disabled={isSubmitting}
                onClick={(): void => setSubmitClicked(true)}
                startIcon={isSubmitting ? <Spinner size="large" /> : null}
              >
                {isSubmitting ? (
                  <>
                    Creating...
                  </>
                ) :
                  <>
                    {isCreatingResign ? 'Create Re-sign Blueprint' : 'Create New Blueprint'}
                  </>
                }
              </Button>
            </div>
          </Container>
        </form>
        <SalesforceOpportunityLinkModal
          alreadyLinkedOpportunity={alreadyLinkedSelectedOpportunity}
          salesforceClientId={clientSelected?.salesforce_client_id}
          setSalesforceValuesObject={setSalesforceValuesObject}
          salesforceValuesObject={salesforceValuesObject}
          toggleSalesforceOpportunityLinkModal={toggleSalesforceOpportunityLinkModal}
          open={salesforceOpportunityLinkModalIsOpen}
        />
      </>
    </AccessControl>
  );
};

export default memo(CreatePackageBasedBlueprint);
