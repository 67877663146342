import React, { memo, useCallback } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import visuallyHidden from '@mui/utils/visuallyHidden';
import {Box} from '@sprnova/nebula';
import { KeyStrategy } from '../BlueprintsPackageReviewListTable';
import EnhancedTableHeadProps from './EnhancedTableHeadProps';
import HeadCellType from './HeadCellType';

function EnhancedTableHead(props: EnhancedTableHeadProps): JSX.Element {
  const { order, orderBy, onRequestSort, headCells, className} = props;
  const createSortHandler =
    useCallback((event: React.MouseEvent<unknown>, property: KeyStrategy, isNumeric: boolean) => {
      onRequestSort(event, property, isNumeric);
    }, [onRequestSort]);

  return (
    <TableHead className={className}>
      <TableRow>
        {headCells.map((headCell: Partial<HeadCellType>) => {
          if (!headCell.hidden) {
            return <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.property ? order : false}
            >
              {headCell.label}
              {headCell.sort &&
                <TableSortLabel
                  active={orderBy === headCell.property}
                  disabled={!headCell.sort}
                  direction={orderBy === headCell.property ? order : 'asc'}
                  onClick={(event): void => {
                    if (headCell.sort && headCell.property) {
                      createSortHandler(event, headCell.property, headCell.isNumeric ?? false);
                    }
                  }}
                >
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              }
            </TableCell>;
          }})}
      </TableRow>
    </TableHead>


  );
}

export default memo(EnhancedTableHead);
