import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, IconButton, Spinner, Typography, Alert, AlertTitle, PlusIcon, Box, Updates, TrashIcon, useSnackbar } from '@sprnova/nebula';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { useLazyGetNovaAIChatHistoryQuery } from 'api/crudGraphQL/nova_ai/getNovaAIChatHistory';
import { useLazyGetNovaAIChatSessionsQuery } from 'api/crudGraphQL/nova_ai/getNovaAIChatSessions';
import { useGetNovaAIReasonsQuery } from 'api/crudGraphQL/nova_ai/getNovaAIReasons';
import { useUpdateNovaAIChatMutation } from 'api/crudGraphQL/nova_ai/updateNovaAIChat';
import { useGetClientPublicQuery } from 'api/crudGraphQL/public/clients/getClient';
import { useLazyGetNovaAIChatHistoryPublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaAIChatHistoryPublic';
import { useLazyGetNovaAIChatSessionsPublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaAIChatSessionsPublic';
import { useGetNovaAIReasonsPublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaAIReasonsPublic';
import { useGetNovaChallengesPublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaChallengesPublic';
import { useUpdateNovaAIChatPublicMutation } from 'api/crudGraphQL/public/nova_ai/updateNovaAIChatPublic';
import { formatFullDate } from 'utils/date/dateFormatter';
import isExternalUser from 'utils/helpers/isExternalUser';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { useExternalAccount } from 'utils';
import { useNovaAITier } from 'features/clients/hooks';
import { NovaAIFeedbackReasonsType, NovaAIResponseStatus, NovaAIResponseType, NovaAISessionType } from 'features/entitiesRedux/models/nova_ai';
import { useAccount } from 'features/global';
import { NovaGptFeedback, NovaGptPageSpinner , ChatBubble, DatasetTable, DeleteSessionDialog, SessionListSkeleton, OtherFeedbackDialog, SuggestedPrompts } from './components';
import HelpCenterModal from './components/HelpCenterModal';
import LoadTimeText from './components/LoadTimeText/LoadTimeText';
import NewSessionPage from './components/NewSessionPage';
import NovaGptFooter from './components/NovaGptFooter';
import NovaGptHeader from './components/NovaGptHeader';
import { OtherFeedbackFields } from './components/OtherFeedbackDialog';
import { useAskNovaAI } from './hooks';
import { AskNovaAIResponse } from './hooks/useAskNovaAI';
import { mergeSession, transformResponse } from './utils';
import { AskNovaAIApi } from '../NovaGptDrawer/hooks/useAskNovaAI';
import css from './NovaGpt.module.scss';

type NovaGptProps = {
  drawerOpen?: boolean;
  bannerPrompt?: string;
  setBannerPrompt?: Dispatch<SetStateAction<string>>;
  isFromBanner?: boolean;
  setIsFromBanner?: Dispatch<SetStateAction<boolean>>;
  isDrawer?: boolean
  closeDrawer?: () => void
}

export enum FeedbackType {
  positive = 'positive',
  negative = 'negative',
  help = 'help',
}

export enum AITiers {
  Essential = 'essential',
  Accelerate = 'accelerate',
  Enterprise = 'enterprise',
  Prospect = 'prospect',
}

export type PromptIdentifierType = {
  version?: number;
  promptGuid: string;
}

export interface ApiResponse {
  [key: string]: string | number;
}

const messages = {
  unknownError: 'novaGPT is having trouble generating a response. Please try again or try submitting a new prompt. If the issue persists, contact nova support.',
};

const NovaGpt = ({drawerOpen, bannerPrompt, isFromBanner, setIsFromBanner, isDrawer, closeDrawer}: NovaGptProps): JSX.Element => {
  const [questionsSubmitted, setQuestionsSubmitted] = useState<string[]>([]);
  const [answers, setAnswers] = useState<NovaAIResponseType[][]>([]);
  const [prompt, setPrompt] = useState<string>('');
  const [promptGuids, setPromptGuids] = useState<PromptIdentifierType[][]>([]);
  const { account } = useAccount();
  const latestMessageRef = useRef<HTMLDivElement>(null);
  const latestFeedbackRef = useRef<HTMLDivElement>(null);
  const { clientId: idRaw } = useParams<{ [clientId: string]: string }>();
  const [otherFeedbackDialogOpen, toggleOtherFeedbackDialog] = useReducer((otherFeedbackDialogOpen) => !otherFeedbackDialogOpen, false);
  const [deleteSessionDialogOpen, toggleDeleteSessionDialog] = useReducer((otherFeedbackDialogOpen) => !otherFeedbackDialogOpen, false);
  const [showFeedbackOptions, setShowFeedbackOptions] = useState<PromptIdentifierType[]>([]);
  const [showHelpOptions, setShowHelpOptions] = useState<PromptIdentifierType[]>([]);
  const [showSupportRequestConfirmation, setShowSupportRequestConfirmation] = useState<PromptIdentifierType[]>([]);
  const [showAnalystReviewConfirmation, setShowAnalystReviewConfirmation] = useState<boolean>(false);
  const [activeThumbsUp, setActiveThumbsUp] = useState<(PromptIdentifierType | undefined)[]>([]);
  const [activeThumbsDown, setActiveThumbsDown] = useState<(PromptIdentifierType | undefined)[]>([]);
  const [currentPromptGuid, setCurrentPromptGuid] = useState<string>('');
  const [currentPromptVersion, setCurrentPromptVersion] = useState<number>(0);
  const [currentResponseGuid, setCurrentResponseGuid] = useState<string>('');
  const [currentResponseVersion, setCurrentResponseVersion] = useState<number>(0);
  const [answerIndices, setAnswerIndices] = useState<Record<number, number>>({});
  const [sessionList, setSessionList] = useState<NovaAISessionType[]>([]);
  const [sessionSelected, setSessionSelected] = useState<NovaAISessionType>();
  const [sessionToDelete, setSessionToDelete] = useState<NovaAISessionType | null>(null);
  const [loadingSessions, setLoadingSession] = useState<boolean>(false);
  const [loadingChat, setLoadingChat] = useState<boolean>(false);
  const [isLoadingDisplayedAt, setIsLoadingDisplayedAt] = useState<boolean>(false);
  const [newSession, setNewSession] = useState<boolean>(false);
  const [unpersistedSessionGuids, setUnpersistedSessionGuids] = useState<string[]>([]);
  const [helpCenterModalOpen, toggleHelpCenterModal] = useReducer((open) => !open, false);
  const parsedClientId = useMemo(() => Number.parseInt(idRaw), [idRaw]);

  const { addSnackbar } = useSnackbar();
  const [handleLockButtonClick, setHandleLockButtonClick] = useState<(() => void) | null>(null);
  const isExternal = isExternalUser();
  const externalUser = useExternalAccount();

  const { data: internalClient } = useGetClientQuery({
    id: parsedClientId,
    projection: { id: true, name: true }
  }, { refetchOnMountOrArgChange: true, skip: isExternal });

  const { data: externalClient } = useGetClientPublicQuery({
    id: parsedClientId,
    projection: {
      id: true,
      name: true,
    }
  }, { skip: !parsedClientId || !isExternal});

  const [fetchNovaAIChatSessions] = useLazyGetNovaAIChatSessionsQuery();
  const [fetchNovaAIChatSessionsPublic] = useLazyGetNovaAIChatSessionsPublicQuery();

  const { data: challengeData, refetch: refetchChallengeData } = useGetNovaChallengesPublicQuery(
    {
      client_id: Number.parseInt(idRaw),
      projection: {
        step: true,
        status: true,
        question_limit: true,
        question_count: true,
        message: true,
        question_trigger: true,
        action: true,
      },
    }, { skip: !isExternal || (externalUser?.is_pdm_employee === true || externalUser?.is_pdm_employee === undefined) }
  );

  const { aiTier, isFetching: isLoadingAiTier } = useNovaAITier({ client_id: parseInt(idRaw) }, !parseInt(idRaw));

  const currentChallenge = useMemo(
    () => challengeData?.find((challenge) => challenge.status === 'in_progress'),
    [challengeData]
  );

  const questionLimitReached = useMemo(() => currentChallenge && currentChallenge?.question_count >= currentChallenge?.question_limit, [currentChallenge]);

  useEffect(() => {
    // Conditionally set up polling behavior based on `currentChallenge`
    if (drawerOpen && currentChallenge && (currentChallenge?.question_count >= currentChallenge?.question_trigger)) {
      const interval = setInterval(() => {
        refetchChallengeData(); // manually trigger refetch
      }, 5000); // Polling interval set to 5000ms

      // Clean up interval on component unmount or when condition changes
      return (): void => clearInterval(interval);
    }
  }, [currentChallenge, drawerOpen, refetchChallengeData]);

  useEffect(() => {
    if (currentChallenge?.action) {
      // Parse the HTML string and extract the onClick content
      const parser = new DOMParser();
      const doc = parser.parseFromString(currentChallenge.action, 'text/html');
      const aTag = doc.querySelector('a');

      if (aTag && aTag.getAttribute('onclick')) {
        const onClickContent = aTag.getAttribute('onclick');

        // Create a function dynamically
        const dynamicFunction = new Function(onClickContent || '');

        setHandleLockButtonClick(() => {
          return (): void => {
            dynamicFunction();
          };
        });
      }
    }
  }, [currentChallenge]);

  const fetchSessions = useCallback(async () => {
    setLoadingSession(true);
    try {
      const requestFn = isExternal
        ? fetchNovaAIChatSessionsPublic
        : fetchNovaAIChatSessions;

      const sessions = await requestFn({
        client_id: parseInt(idRaw),
        projection: {
          guid: true,
          latest_prompt: {
            text: true,
          },
          created_at: true
        }}).unwrap();
      setSessionList(sessions);
      setSessionSelected(sessions[0]);
    }
    catch(error) {
      console.error('Error loading session history', error);
      addSnackbar({
        variant: 'error',
        message: 'Error loading session history.',
      });
    }
    setNewSession(false);
    setLoadingSession(false);
  }, [addSnackbar, fetchNovaAIChatSessions, fetchNovaAIChatSessionsPublic, idRaw, isExternal]);

  //Get Chat history for specific session
  const [fetchNovaAIChatHistory] = useLazyGetNovaAIChatHistoryQuery();
  const [fetchNovaAIChatHistoryPublic] = useLazyGetNovaAIChatHistoryPublicQuery();
  useEffect(() => {
    if (sessionSelected?.guid) {
      (async (): Promise<void> => {
        setLoadingChat(true);
        try {
          const requestFn = isExternal
            ? fetchNovaAIChatHistoryPublic
            : fetchNovaAIChatHistory;
          const chatHistory = await requestFn({
            session_guid: sessionSelected.guid,
            projection: {
              prompt: {
                created_at: true,
                guid: true,
                text: true,
              },
              responses: {
                created_at: true,
                ended_at: true,
                displayed_at: true,
                error: true,
                feedback: true,
                json_response: true,
                prompt_guid: true,
                prompt_text: true,
                response: true,
                session_guid: true,
                status: true,
                version: true,
              }
            }
          }).unwrap();
          const questions = chatHistory.map((chat) => chat.prompt.text);
          const answers = chatHistory.map(({ responses }) => responses.map(transformResponse));
          const versionList = chatHistory.map((chat) => chat.responses.map((response) => response.version));
          const feedbackList = chatHistory.map((chat) => chat.responses.map((response) => response.feedback));
          const guidList = chatHistory.map((chat) => chat.prompt.guid);
          const promptList = versionList.map((val, key) => val.map((val) => ({promptGuid: guidList[key], version: val})));
          const positiveFeedbackPrompts = feedbackList.map((feedback, chatIndex) =>
            feedback.map((feedbackItem, responseIndex) => (
              feedbackItem === 1 ? {
                promptGuid: guidList[chatIndex],
                version: versionList[chatIndex][responseIndex],
              } : undefined))
          ).flat().filter(prompt => prompt !== undefined);
          const negativeFeedbackPrompts = feedbackList.map((feedback, chatIndex) =>
            feedback.map((feedbackItem, responseIndex) => (
              feedbackItem === 0 ? {
                promptGuid: guidList[chatIndex],
                version: versionList[chatIndex][responseIndex],
              } : undefined))
          ).flat().filter(prompt => prompt !== undefined);
          setQuestionsSubmitted(questions);
          setPromptGuids(promptList);
          setAnswers(answers);
          setActiveThumbsUp(positiveFeedbackPrompts);
          setActiveThumbsDown(negativeFeedbackPrompts);
        }
        catch(error) {
          console.error('Error loading chat history', error);
          addSnackbar({
            variant: 'error',
            message: 'Error loading chat history.',
          });
        }
        setLoadingChat(false);
      })();
    }
  }, [addSnackbar, fetchNovaAIChatHistory, fetchNovaAIChatHistoryPublic, sessionSelected, isExternal]);

  // Update answerIndices to point to the last answer by default.
  useEffect(() => {
    const updatedIndices = answers.reduce((acc, answer, index) => {
      acc[index] = answer.length - 1;
      return acc;
    }, {} as Record<number, number>);
    setAnswerIndices(updatedIndices);
  }, [answers]);

  const mixpanelTrack = useMixpanelTrack();
  const trackHelpCenterMixpanel = useCallback((buttonClicked: string ): void => {
    const eventName = `${buttonClicked} - User Clicks`;
    const options = {
      clientId: isExternal ? externalClient?.id : internalClient?.id,
      clientName: isExternal ? externalClient?.name : internalClient?.name,
      accountId: isExternal ? externalUser?.id : account?.id,
      accountName: isExternal ? externalUser?.name : account?.name,
      accountEmail: isExternal ? externalUser?.email : account?.email,
      isPdmEmployee: isExternal ? externalUser?.is_pdm_employee : true,
      buttonClicked
    };
    mixpanelTrack(eventName, options);
  },[account?.email, account?.id, account?.name, externalClient?.id, externalClient?.name, externalUser?.email, externalUser?.id, externalUser?.is_pdm_employee, externalUser?.name, internalClient?.id, internalClient?.name, isExternal, mixpanelTrack]);

  const handlePrevious = useCallback((answerKey: number): void => {
    setAnswerIndices((prevIndices) => ({
      ...prevIndices,
      [answerKey]: Math.max((prevIndices[answerKey] || 0) - 1, 0),
    }));
  }, []);

  const handleNext = useCallback((answerKey: number, length: number): void => {
    setAnswerIndices((prevIndices) => ({
      ...prevIndices,
      [answerKey]: Math.min((prevIndices[answerKey] || 0) + 1, length - 1),
    }));
  }, []);

  const handleShowFeedbackOptions = useCallback((promptIdentifer: PromptIdentifierType) => {
    setShowFeedbackOptions((showFeedbackOptions) => [...showFeedbackOptions, promptIdentifer]);
  }, []);

  const handleHideFeedbackOptions = useCallback((promptIdentifer: PromptIdentifierType) => {
    setShowFeedbackOptions((showFeedbackOptions) => showFeedbackOptions.filter(obj => obj.promptGuid !== promptIdentifer.promptGuid && obj.version !== promptIdentifer.version));
  }, []);


  const handleShowHelpOptions = useCallback((promptIdentifer: PromptIdentifierType) => {
    setShowHelpOptions((showHelpOptions) => [...showHelpOptions, promptIdentifer]);
  }, []);

  const handleHideHelpOptions = useCallback((promptIdentifer: PromptIdentifierType) => {
    setShowHelpOptions((showHelpOptions) => showHelpOptions.filter(obj => obj.promptGuid !== promptIdentifer.promptGuid ));
  }, []);

  const isFeedbackOpen = useCallback((promptIdentifer: PromptIdentifierType) => {
    return showFeedbackOptions.some(obj => obj.promptGuid === promptIdentifer.promptGuid && obj.version === promptIdentifer.version);
  }, [showFeedbackOptions]);

  const isHelpOpen = useCallback((promptIdentifer: PromptIdentifierType) => {
    return showHelpOptions.some(obj => obj.promptGuid === promptIdentifer.promptGuid && obj.version === promptIdentifer.version);
  }, [showHelpOptions]);

  const { askNovaAI, refresh, isLoading } = useAskNovaAI();
  const [updateNovaAIChatRequest] = useUpdateNovaAIChatMutation();
  const [updateNovaAIChatRequestPublic] = useUpdateNovaAIChatPublicMutation();

  const updateNovaAIChat = isExternal
    ? updateNovaAIChatRequestPublic
    : updateNovaAIChatRequest;
  const { data: optionalFeedbackInternal } = useGetNovaAIReasonsQuery({}, { skip: !drawerOpen || isExternal });
  const { data: optionalFeedbackPublic } = useGetNovaAIReasonsPublicQuery({}, { skip: !drawerOpen || !isExternal });
  const optionalFeedback = optionalFeedbackInternal || optionalFeedbackPublic;

  const addResponse = useCallback((response: NovaAIResponseType) => {
    const { version, prompt_guid } = response;

    if (!prompt_guid) {
      throw new Error('Missing prompt_guid from nova EI response.');
    }

    const currentPrompt = [{version, promptGuid: prompt_guid}];
    const promptIndex = promptGuids.findIndex(subArray => subArray.some(prompt => prompt.promptGuid === prompt_guid));
    const isRegeneratedPrompt = promptIndex !== -1;

    let updatedPromptGuids: PromptIdentifierType[][];
    let updatedAnswers: NovaAIResponseType[][];

    /**
     * Below, we're going to either add the first response to a new prompt or another response to an existing prompt (regeneration).
     *
     * Both promptGuids and answers are arrays of arrays, where the first dimension is the prompt identifier (prompt GUID)
     * and the second dimension is the version identifier (version number).
     */

    if (isRegeneratedPrompt) {
      // Here, we want to add another response (different version) to an existing prompt.

      /**
       * Here, we need to update promptGuids such that a new version n exists in the existing array of prompts:
       * [..., [{version: 1, promptGuid: 'abc'}, {version: n, promptGuid: 'abc'}].
       */
      const promptGuidsCopy = [...promptGuids];
      const previousPromptGuid = promptGuidsCopy[promptIndex];
      promptGuidsCopy[promptIndex] = [...previousPromptGuid, currentPrompt[0]];
      updatedPromptGuids = promptGuidsCopy;

      /**
       * Here, we need to update answers such that a new version n exists in the existing array of answers:
       * [..., [{version: 1, prompt_text: 'Foo?'}, {version: n, prompt_text: 'Foo?'}]]
       */
      const answersCopy = [...answers];
      const previousAnswer = answersCopy[promptIndex];
      answersCopy[promptIndex] = [...previousAnswer, response];
      updatedAnswers = answersCopy;
    } else {
      // This is the first response to a new prompt.

      /**
       * Here, we need to update promptGuids such that it adds a new array to the array of arrays:
       * [..., [{version: 1, promptGuid: 'abc'}]]
       */
      updatedPromptGuids = [...promptGuids, currentPrompt];

      /**
       * Here, we need to update answers such that it adds a new array to the array of arrays:
       * [..., [{version: 1, prompt_text: 'Foo?'}]]
       */
      updatedAnswers = [...answers, [response]];
    }

    setPromptGuids(updatedPromptGuids);
    setAnswers(updatedAnswers);
  }, [answers, promptGuids]);

  useEffect(() => {
    function updateCurrentAnswerDisplayedAt(): void {
      const selectedAnswerIndex = answers.findIndex(answer => {
        return answer.some((answerIndex) => answerIndex.prompt_guid === currentResponseGuid);
      });
      // Here we check if the answer array has a guid that is the same as the selected response guid and it doesn't have a displayed_at value yet
      if(currentResponseGuid && currentResponseVersion && answers?.[selectedAnswerIndex]?.some(answer => currentResponseGuid === answer.prompt_guid && !answer.displayed_at)){
        const currentDisplayedAt = new Date().toISOString().replace(/[TZ]/g, ' ').trim();
        updateNovaAIChat(
          {
            prompt_guid: currentResponseGuid,
            version: currentResponseVersion,
            displayed_at: currentDisplayedAt,
            projection: {response: true, displayed_at: true}
          }
        ).unwrap().then((response) => {
          const currentDisplayedAt = response.displayed_at;
          const updatedAnswers = [...answers];
          updatedAnswers[selectedAnswerIndex][updatedAnswers[selectedAnswerIndex].length - 1].displayed_at = currentDisplayedAt;
          setAnswers(updatedAnswers);
        });
      }
    }
    updateCurrentAnswerDisplayedAt();
  },[answers, currentResponseVersion, currentResponseGuid, isLoadingDisplayedAt, updateNovaAIChat]);

  useEffect(() => {
    const selectedAnswerIndex = answers.findIndex(answer => {
      return answer.find((value) => value.prompt_guid === currentResponseGuid);
    });
    if(currentResponseGuid && isLoadingDisplayedAt && answers?.[selectedAnswerIndex]?.[answers?.[selectedAnswerIndex].length-1]?.displayed_at){
      setCurrentResponseGuid('');
      setCurrentResponseVersion(0);
      setIsLoadingDisplayedAt(false);
    }
  }, [answers, currentResponseGuid, isLoadingDisplayedAt]);

  const setSession = useCallback((response: NovaAIResponseType) => {
    const {
      prompt_guid,
      prompt_text,
      session_guid,
    } = response;

    if (!session_guid) {
      throw new Error('Missing session_guid from nova EI response.');
    }

    const existingSession = sessionList.find((session) => session.guid === session_guid);

    let updatedSession: NovaAISessionType;

    if (existingSession) {
      // The incoming response is to a prompt on an existing session.
      updatedSession = { ...existingSession };
      updatedSession.latest_prompt = {
        guid: prompt_guid,
        text: prompt_text,
      };
    } else {
      // The incoming response is to the first prompt of a new session.
      updatedSession = {
        guid: session_guid,
        latest_prompt: {
          guid: prompt_guid,
          text: prompt_text,
        },
        // ISO 8601 UTC format without the time designator (T)
        // and the Zulu time (Z) suffix.
        created_at: new Date().toISOString().replace(/[TZ]/g, ' ').trim(),
      };
    }
    setSessionList((sessions) => mergeSession(sessions, updatedSession));
    setSessionSelected(updatedSession);
  }, [sessionList]);

  // Hacky: Keep the session title in-sync with the chat history.
  useEffect(() => {
    const latestAnswers = answers[answers?.length - 1];
    const latestAnswer = latestAnswers?.[latestAnswers?.length - 1];

    if (sessionSelected && latestAnswer?.session_guid === sessionSelected?.guid) {
      const updatedSession = {
        ...sessionSelected,
        latest_prompt: {
          ...sessionSelected?.latest_prompt,
          text: latestAnswer.prompt_text
        }
      };
      setSessionList((sessions) => mergeSession(sessions, updatedSession));
    }
  }, [answers, sessionSelected, sessionSelected?.guid, sessionSelected?.latest_prompt?.text]);

  /**
   * Handles a response from the nova EI endpoints by displaying a chat or error message on the UI.
   *
   * @param response The response that is to be handled.
   */
  const handleNovaEIResponse = useCallback(async ({ result }: AskNovaAIResponse): Promise<void> => {

    // If this session was marked as unpersisted, then unmark it because one or more chats persisted.
    setUnpersistedSessionGuids((guids) => [...guids.filter((guid) => guid !== result?.session_guid)]);

    const transformedResponse = transformResponse(result);
    setSession(transformedResponse);
    addResponse(transformedResponse);
    refetchChallengeData();
  }, [addResponse, refetchChallengeData, setSession]);

  /**
   * Fetches a response from the novaEI endpoints using the provided prompt and client ID. Displays
   * an error message if an error occurs while fetching a response.
   *
   * @param prompt The user query or prompt.
   * @param clientId The unique identifier of the client.
   * @returns A Promise that resolves with the response or rejects with `undefined` if an error occurred.
   */
  const getNovaEIResponse = useCallback(async (prompt: string, clientId: number, currentPromptGuid?: string): Promise<AskNovaAIResponse> => {
    try {
      if (currentPromptGuid && sessionSelected?.guid) {
        return await refresh(prompt, clientId, currentPromptGuid, sessionSelected.guid);
      } else {
        return await askNovaAI(prompt, clientId, sessionSelected?.guid);
      }
    } catch (error) {
      // Append error to chat history.
      const sessionGuid = sessionSelected?.guid ?? crypto.randomUUID();
      handleNovaEIResponse({
        result: {
          error: messages.unknownError,
          prompt_guid: crypto.randomUUID(),
          prompt_text: prompt,
          session_guid: sessionGuid,
          status: NovaAIResponseStatus.Failed,
        },
        api: AskNovaAIApi.Response,
      });

      // Remember that this new session is not persisted.
      if (!sessionSelected?.guid && !unpersistedSessionGuids.includes(sessionGuid)) {
        setUnpersistedSessionGuids((guids) => [...guids, sessionGuid]);
      }

      console.error('Error occurred while fetching the response from novaGPT.', error);

      return Promise.reject(undefined);
    }
  }, [askNovaAI, handleNovaEIResponse, refresh, sessionSelected?.guid, unpersistedSessionGuids]);

  const handleRefresh = useCallback((currentQuestion: string, currentPrompt?: PromptIdentifierType) => {
    setIsLoadingDisplayedAt(true);
    getNovaEIResponse(currentQuestion, parseInt(idRaw), currentPrompt?.promptGuid)
      .then(response => {
        handleNovaEIResponse(response);
        if(response.result.prompt_guid && response.result.version){
          setCurrentResponseGuid(response.result.prompt_guid);
          setCurrentResponseVersion(response.result.version);
        }
      });
  }, [getNovaEIResponse, handleNovaEIResponse, idRaw]);

  const handleOpenDrawer = useCallback(() => {
    fetchSessions();
    // Hide intercom button while drawer is open so there is no overlap
    if ((window as Window).Intercom) {
      (window as Window).Intercom('update', {
        'hide_default_launcher': true
      });
    }
  },[fetchSessions]);

  useEffect(() => {
    if (!sessionList?.length && !isDrawer) {
      fetchSessions();
    } else if (isDrawer && drawerOpen) {
      handleOpenDrawer();
    }
  }, [drawerOpen, fetchSessions, handleOpenDrawer, isDrawer, sessionList?.length]);

  const handleQuestionSelect = useCallback(async (question: string) => {
    setQuestionsSubmitted([...questionsSubmitted, question]);
    setIsLoadingDisplayedAt(true);
    await getNovaEIResponse(question, parseInt(idRaw))
      .then(response => {
        handleNovaEIResponse(response);
        if(response.result.prompt_guid && response.result.version){
          setCurrentResponseGuid(response.result.prompt_guid);
          setCurrentResponseVersion(response.result.version);
        }
      });
  }, [getNovaEIResponse, handleNovaEIResponse, idRaw, questionsSubmitted]);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setPrompt(e.target.value);
  }, []);

  const createNewSession = useCallback((): void => {
    setQuestionsSubmitted([]);
    setPromptGuids([]);
    setAnswers([]);
    setActiveThumbsUp([]);
    setActiveThumbsDown([]);
    setNewSession(true);
    setSessionSelected(undefined);
  }, []);

  const handleSubmit = useCallback(async (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    setQuestionsSubmitted([...questionsSubmitted, prompt]);
    setPrompt('');
    setIsLoadingDisplayedAt(true);
    await getNovaEIResponse(prompt, parseInt(idRaw))
      .then(response => {
        handleNovaEIResponse(response);
        if(response.result.prompt_guid && response.result.version){
          setCurrentResponseGuid(response.result.prompt_guid);
          setCurrentResponseVersion(response.result.version);
        }
      });
    setIsFromBanner?.(false);
  }, [questionsSubmitted, prompt, getNovaEIResponse, idRaw, setIsFromBanner, handleNovaEIResponse]);

  // This checks if the drawer is open and loaded so the prompt can be loaded
  const loadBannerPrompt = drawerOpen && bannerPrompt && isFromBanner && !loadingChat && !loadingSessions && !isLoading;
  useEffect(() => {
    if(loadBannerPrompt){
      setPrompt(bannerPrompt);
    }
  },[bannerPrompt, drawerOpen, isFromBanner, isLoading, loadBannerPrompt, loadingChat, loadingSessions, setPrompt]);

  // This checks if the novaGPT drawer is ready to submit the question
  const submitBannerPrompt = drawerOpen && prompt.length > 0 && isFromBanner && !loadingChat && !loadingSessions && !isLoading;
  useEffect(() => {
    if(submitBannerPrompt){
      handleSubmit();
    }
  }, [prompt, drawerOpen, handleSubmit, isFromBanner, setIsFromBanner, loadingChat, loadingSessions, isLoading, submitBannerPrompt]);

  // scroll to latest message sent
  useEffect(() => {
    if (latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [questionsSubmitted, answers, drawerOpen]);

  // scroll to latest feedback block
  useEffect(() => {
    const isLatestPromptThumbedDown =
      activeThumbsDown?.[activeThumbsDown?.length - 1]?.promptGuid ===
      promptGuids?.[promptGuids?.length - 1]?.[0]?.promptGuid;
    if (isLatestPromptThumbedDown) {
      if (latestFeedbackRef.current) {
        latestFeedbackRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, [activeThumbsDown, promptGuids]);

  // Open intercom survey after a certain number of answers have been returned
  useEffect(() => {
    const numberOfAnswersBeforeSurvey = 7;
    if (answers.length === numberOfAnswersBeforeSurvey) {
      if ((window as Window).Intercom) {
        (window as Window).Intercom('startSurvey', 39328020);
      }
    }
  }, [answers.length]);

  const handleFeedback = useCallback(async (feedbackType: FeedbackType, promptGuid: string, version: number) => {
    if (promptGuid) {
      const prompt = {version, promptGuid};
      try {
        if (feedbackType === FeedbackType.positive) {
          setActiveThumbsUp((activeThumbsUp) => [...activeThumbsUp, prompt]);
          // submit positive feedback
          await updateNovaAIChat({prompt_guid: promptGuid, version: prompt.version,feedback: 1});
          // show notification
          addSnackbar({
            variant: 'success',
            message: 'Thank you for your response.',
          });
        }  else if (feedbackType === FeedbackType.negative) {
          setActiveThumbsDown((activeThumbsDown) => [...activeThumbsDown, prompt]);
          // show additional feedback options
          handleShowFeedbackOptions(prompt);
          // submit negative feedback
          await updateNovaAIChat({prompt_guid: promptGuid, version: prompt.version, feedback: 0});
        }
        else{
          return showHelpOptions.some((helpOption) => helpOption?.promptGuid === prompt?.promptGuid && helpOption?.version === prompt?.version) ? handleHideHelpOptions(prompt) : handleShowHelpOptions(prompt);
        }
      } catch (error) {
        console.error('Error providing feedback', error);
        addSnackbar({
          variant: 'error',
          message: 'Error recording feedback.',
        });
      }
    }
  }, [addSnackbar, handleHideHelpOptions, handleShowFeedbackOptions, handleShowHelpOptions, showHelpOptions, updateNovaAIChat]);

  const handleNoOptionalFeedback = useCallback(async (feedback: NovaAIFeedbackReasonsType, promptGuid: string, version: number) => {
    try {
      const prompt = {version, promptGuid};
      handleHideFeedbackOptions(prompt);
      await updateNovaAIChat({prompt_guid: promptGuid, feedback: 0, version: prompt.version, feedback_reason_id: feedback?.id});

      // show notification
      addSnackbar({
        variant: 'success',
        message: 'Thank you for your response.',
      });
    }
    catch (error) {
      console.error('Error providing feedback', error);
      addSnackbar({
        variant: 'error',
        message: 'Error recording feedback.',
      });
    }
  },[addSnackbar, handleHideFeedbackOptions, updateNovaAIChat]);

  const handleOptionalFeedback = useCallback(async (feedback: NovaAIFeedbackReasonsType, promptGuid: string, version: number) => {
    try {
      const prompt = {version, promptGuid};
      if (feedback?.slug === 'other' || feedback?.slug === 'contact_analyst') {
        //Show optional feedback dialog
        setCurrentPromptGuid(promptGuid);
        setCurrentPromptVersion(version);
        toggleOtherFeedbackDialog();
      } else {
        // Don't show feedback options for nova Tier essentials
        if(!isLoadingAiTier && aiTier?.slug === AITiers.Essential){
          handleHideFeedbackOptions(prompt);
          await updateNovaAIChat({prompt_guid: promptGuid, feedback: 0, version: prompt.version, feedback_reason_id: feedback?.id});

          // show notification
          addSnackbar({
            variant: 'success',
            message: 'Thank you for your response.',
          });
        }
        else{
          //Response options for higher tiers of novaEI
          setShowAnalystReviewConfirmation(true);
          setCurrentPromptGuid(promptGuid);
          setCurrentPromptVersion(version);
        }
      }
    } catch (error) {
      console.error('Error providing feedback', error);
      addSnackbar({
        variant: 'error',
        message: 'Error recording feedback.',
      });
    }
  }, [addSnackbar, aiTier?.slug, handleHideFeedbackOptions, isLoadingAiTier, updateNovaAIChat]);

  const handleOtherFeedbackDialogSubmit = useCallback(async (data: OtherFeedbackFields) => {
    toggleOtherFeedbackDialog();

    const hasData = data?.support_requested?.trim()?.length > 0
      || data?.feedback_additional?.trim()?.length > 0;

    if (currentPromptGuid && hasData) {
      handleHideHelpOptions({version: currentPromptVersion, promptGuid: currentPromptGuid});
      handleHideFeedbackOptions({version: currentPromptVersion, promptGuid: currentPromptGuid});
      try {

        let support_requested;

        if (data.support_requested === 'true') {
          support_requested = true;
        } else if (data.support_requested === 'false') {
          support_requested = false;
        } else {
          support_requested = undefined;
        }

        await updateNovaAIChat({
          prompt_guid: currentPromptGuid,
          feedback: 0,
          feedback_reason_id: optionalFeedback?.find((feedback) => feedback.slug === 'other')?.id,
          feedback_additional: data.feedback_additional,
          support_requested: support_requested,
          version: currentPromptVersion
        });

        if (support_requested) {
          setShowSupportRequestConfirmation((prompts) => [...prompts, {
            version: currentPromptVersion,
            promptGuid: currentPromptGuid,
          }]);
        }
        addSnackbar({
          variant: 'success',
          message: 'Thank you for your response.',
        });
        handleHideFeedbackOptions({version: currentPromptVersion, promptGuid: currentPromptGuid});
      } catch (error) {
        console.error('Error providing feedback', error);
        addSnackbar({
          variant: 'error',
          message: 'Error recording feedback.',
        });
      }
      setPrompt('');
      setCurrentPromptGuid('');
      setCurrentPromptVersion(0);
    }
  }, [addSnackbar, currentPromptGuid, currentPromptVersion, handleHideFeedbackOptions, handleHideHelpOptions, optionalFeedback, updateNovaAIChat]);

  const handleOtherFeedbackDialogCancel = useCallback(async () => {
    toggleOtherFeedbackDialog();
    setCurrentPromptGuid('');
  }, []);

  const handleSessionSelect = useCallback((newSession: NovaAISessionType) => {
    const oldSession = sessionSelected;

    // If the old session is not persisted, then remove all traces of it.
    if (oldSession?.guid && unpersistedSessionGuids.includes(oldSession.guid)) {
      setUnpersistedSessionGuids((guids) => [...guids.filter((guid) => guid !== oldSession.guid)]);
      setSessionList((sessions) => [...sessions.filter((session) => session.guid !== oldSession.guid)]);
    }

    if (oldSession?.guid !== newSession?.guid) {
      setAnswers([]);
      setSessionSelected(newSession);
    }

    setNewSession(false);
  }, [sessionSelected, unpersistedSessionGuids]);

  /**
   * Renders the responses to a user prompt.
   *
   * @param question The question that was asked by the user.
   * @param responses The responses to the question.
   * @param activeResponse The response in responses that is to be rendered.
   * @param index The index of activeResponse in the responses array.
   */
  const renderResponse = useCallback((question: string, responses: NovaAIResponseType[], activeResponse: NovaAIResponseType, answerKey: number) => {
    const isLatestResponse = answerKey === answers.length - 1;
    const answerIndex = answerIndices[answerKey] || 0;
    const currentPrompt = promptGuids[answerKey][answerIndex];
    const shouldShowSupportRequestConfirmation = showSupportRequestConfirmation.some(prompt => prompt.promptGuid === currentPrompt.promptGuid && prompt.version === currentPrompt.version);

    const hasNonEmptyCompletedResponse = activeResponse?.status === 'completed'
      && activeResponse?.response
      && activeResponse.response.trim().length > 0;

    let response: JSX.Element;
    let parsedJsonResponse;

    if (activeResponse?.json_response) {
      const parsedActiveResponse = JSON.parse(activeResponse?.json_response);
      // Parse JSON and filter out empty objects
      parsedJsonResponse = {
        data: parsedActiveResponse?.data?.filter((row: ApiResponse) => Object.keys(row)?.length > 0),
        suggestions: parsedActiveResponse?.suggestions?.filter((suggestion: string) => Object.keys(suggestion)?.length > 0),
      };
    }

    if (hasNonEmptyCompletedResponse) {
      response = (
        <div className={css.answerContainer} ref={isLatestResponse ? latestMessageRef : undefined}>
          <div className={css.responseHeader}>
            <div className={css.user}>
              <Avatar src="/svg/novagpt-icon.svg" size='xs' className={css.avatar} disableRandomColor />
              <Typography alignItems={'center'}>novaGPT</Typography>
            </div>
            <LoadTimeText activeResponse={activeResponse} />
          </div>
          <div style={{margin: '0 0 0 30px'}}>
            <ChatBubble variant="light">
              {parsedJsonResponse?.suggestions?.length ?
                <SuggestedPrompts
                  response={activeResponse?.response?.trim()}
                  suggestions={parsedJsonResponse.suggestions}
                  onQuestionSelect={handleQuestionSelect}
                  questionLimitReached={questionLimitReached}
                /> :
                <>
                  {activeResponse?.response?.trim()}
                  {(parsedJsonResponse?.data && parsedJsonResponse?.data?.length > 1) && <DatasetTable data={parsedJsonResponse.data}/>}
                </>
              }
            </ChatBubble>
            <NovaGptFeedback
              account={isExternal ? externalUser : account }
              answerIndex={answerIndex}
              answer={responses}
              clientId={parseInt(idRaw)}
              handleNext={(answerLength): void => handleNext(answerKey, answerLength)}
              handlePrevious={(): void => handlePrevious(answerKey)}
              activeThumbsUp={activeThumbsUp}
              activeThumbsDown={activeThumbsDown}
              optionalFeedback={optionalFeedback ?? []}
              handleOptionalFeedback={handleOptionalFeedback}
              handleFeedback={handleFeedback}
              handleRefresh={(): void => handleRefresh(question, currentPrompt)}
              currentPrompt={currentPrompt}
              ref={latestFeedbackRef}
              isLoading={isLoading}
              showHelpFeedback={isHelpOpen(currentPrompt)}
              showOptionalFeedback={isFeedbackOpen(currentPrompt)}
              showSupportRequestConfirmation={shouldShowSupportRequestConfirmation}
              showAnalystReviewConfirmation={showAnalystReviewConfirmation}
              setShowAnalystReviewConfirmation={setShowAnalystReviewConfirmation}
              handleNoOptionalFeedback={handleNoOptionalFeedback}
              toggleHelpCenterModal={toggleHelpCenterModal}
              trackHelpCenterMixpanel={trackHelpCenterMixpanel}
            />
          </div>
        </div>
      );
    } else {
      response = (
        <Alert
          severity="error"
          className={css.error}
          ref={isLatestResponse ? latestMessageRef : undefined}
        >
          <AlertTitle>Unable to generate response</AlertTitle>
          {activeResponse.error && activeResponse.error.trim().length > 0
            ? activeResponse.error.trim()
            : messages.unknownError}
        </Alert>
      );
    }

    return (currentResponseGuid === activeResponse.prompt_guid && (isLoadingDisplayedAt)) ? null : response;
  }, [account, activeThumbsDown, activeThumbsUp, answerIndices, answers.length, currentResponseGuid, externalUser, handleFeedback, handleNext, handleNoOptionalFeedback, handleOptionalFeedback, handlePrevious, handleRefresh, idRaw, isExternal, isFeedbackOpen, isHelpOpen, isLoading, isLoadingDisplayedAt, optionalFeedback, promptGuids, showAnalystReviewConfirmation, showSupportRequestConfirmation, trackHelpCenterMixpanel, handleQuestionSelect, questionLimitReached]);

  const renderDeleteSessionDialog = useCallback(() => {
    if (sessionToDelete) {
      return (
        <DeleteSessionDialog
          open={deleteSessionDialogOpen}
          toggle={toggleDeleteSessionDialog}
          session={sessionToDelete}
          sessionList={sessionList}
          setSessionList={setSessionList}
          sessionSelected={sessionSelected}
          setSessionSelected={setSessionSelected}
          setAnswers={setAnswers}
          setQuestionsSubmitted={setQuestionsSubmitted}
        />
      );
    }
  }, [sessionToDelete, deleteSessionDialogOpen, sessionList, sessionSelected]);

  const handleDelete = useCallback((session: NovaAISessionType) => {
    setSessionToDelete(session);
    toggleDeleteSessionDialog();
  }, []);

  return (
    <>
      <div
        id="novaGPT"
        className={css.root}
        style={{ height: isDrawer ? '100vh' : `calc(100vh - ${currentChallenge ? '121px' : '73px'})`, display: 'flex', flexDirection: 'column' }} // Account for layout header and banner when calulating height
      >
        <NovaGptHeader
          closeDrawer={closeDrawer}
          toggleHelpCenterModal={toggleHelpCenterModal}
          trackHelpCenterMixpanel={trackHelpCenterMixpanel}
          challengeData={challengeData}
          currentChallenge={currentChallenge}
          isDrawer={isDrawer}
        />
        <div style={{ display: 'flex', flex: 1, minHeight: 0, overflow: 'hidden' }}>
          <div className={css.sessionList} style={{ height: '100%', overflowY: 'auto' }}>
            <Box className={css.chatHistoryHeader} display="flex" justifyContent="space-between" alignItems="center" pb={1} pr={1} pl={2.4} pt={1.2}>
              <Typography variant="h3">Chat History</Typography>
              <IconButton onClick={createNewSession} disabled={questionLimitReached}>
                <PlusIcon />
              </IconButton>
            </Box>
            <Box height="100%" overflow="auto">
              <Updates className={css.updateContainer}>
                {!loadingSessions ? (
                  sessionList?.map((session) => (
                    <Updates.Item
                      key={`session-${session?.guid}`}
                      title={session?.latest_prompt?.text}
                      description={formatFullDate(session?.created_at)}
                      headerEnd={
                        <IconButton onClick={() => handleDelete(session)}>
                          <TrashIcon />
                        </IconButton>
                      }
                      onClick={() => handleSessionSelect(session)}
                      selected={sessionSelected?.guid === session?.guid}
                    />
                  ))
                ) : (
                  <SessionListSkeleton />
                )}
              </Updates>
            </Box>
          </div>
          <div className={css.drawerBody} style={{ flex: 1, overflowY: 'auto' }}>
            {(loadingChat && answers.length < 1) || (loadingSessions && sessionList.length < 1) ? (
              <div className={css.spinnerContainer}>
                <Spinner />
              </div>
            ) : questionsSubmitted?.length ? (
              <div className={css.questionsContainer}>
                {questionsSubmitted.map((question, key) => {
                  const isLastQuestion = key === questionsSubmitted.length - 1;
                  const responses = answers[key];
                  const activeResponse = responses?.[answerIndices[key] || 0];
                  const hasResponses = responses !== undefined && activeResponse !== undefined;
                  return (
                    <div key={key} ref={isLastQuestion ? latestMessageRef : undefined}>
                      <div className={css.questionContainer}>
                        <div className={css.user}>
                          <Avatar src={account?.avatar} size="xs" className={css.avatar} disableRandomColor />
                          <Typography>{account?.name?.split(' ')[0]}</Typography>
                        </div>
                        <div style={{ margin: '0 0 25px 30px' }}>
                          <ChatBubble variant="dark">{question}</ChatBubble>
                        </div>
                      </div>
                      {hasResponses && renderResponse(question, responses, activeResponse, key)}
                    </div>
                  );
                })}
                <NovaGptPageSpinner isLoading={isLoading || isLoadingAiTier || isLoadingDisplayedAt} />
              </div>
            ) : (
              <NewSessionPage
                onQuestionSelect={handleQuestionSelect}
                questionLimitReached={questionLimitReached}
                trackHelpCenterMixpanel={trackHelpCenterMixpanel}
              />
            )}
          </div>
        </div>
        <NovaGptFooter
          prompt={prompt}
          currentChallenge={currentChallenge}
          questionLimitReached={questionLimitReached}
          loadingChat={loadingChat}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          handleLockButtonClick={handleLockButtonClick}
          onInputChange={onInputChange}
        />
      </div>
      <OtherFeedbackDialog
        isOpen={otherFeedbackDialogOpen}
        onCancel={handleOtherFeedbackDialogCancel}
        onSubmit={handleOtherFeedbackDialogSubmit}
      />
      <HelpCenterModal modalOpen={helpCenterModalOpen} toggleModal={toggleHelpCenterModal} trackHelpCenterMixpanel={trackHelpCenterMixpanel}/>
      {renderDeleteSessionDialog()}
    </>
  );
};

export default memo(NovaGpt);
