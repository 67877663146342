export enum ContractDetailsFieldNames {
  Client = 'Client',
  Appraisal = 'Appraisal',
  SalesforceAccount = 'Salesforce Account',
  SalesforceOpportunity = 'Salesforce Opportunity',
  TypeOfContract = 'Type of Contract',
  LengthOfContract = 'Length of Contract',
  PartnerReferral = 'Partner Referral',
  EmployeeReferral = 'Employee Referral',
  Pillar = 'Pillar',
  TypeOfLead = 'Type of Lead',
  StartDate = 'Start Date',
  EndDate = 'End Date', // Start date + length of contract - 1 day
  GuaranteedTerm = 'Guaranteed Term',
  EndDateGuaranteedTerm = 'End Date (Guaranteed Term)',
  InternalCommission = 'Internal Commission',
  InternalCommissionGuaranteedTerm = 'Internal Commission (Guaranteed Term)',
  SalesRep = 'Sales Rep',
  AdSpendCoverageFees = 'Does this Blueprint contain ad spend overage fees in the contract?',
  IsVabo = 'Does this client include VABO? (Ad Spend or Performance)',
  VaboType = 'VABO Type',
}

export enum ContractDetailsKeys {
  AuditId = 'audit_id',
  Type = 'type',
  SalesforceOpportunityId = 'salesforce_opportunity_id',
  SalesforceIsPrimary = 'salesforce_is_primary',
  SalesforceClientId = 'salesforce_client_id',
  Length = 'length',
  PillarId = 'pillar_id',
  LeadSourceId = 'lead_source_id',
  PartnerId = 'partner_id',
  LeadSourceEmployeeId = 'lead_source_employee_id',
  Date = 'date',
  GuaranteedTerm = 'guaranteed_term',
  SalesRep = 'author_id',
  AdSpend = 'ad_spend',
  IsVabo = 'is_vabo',
  VaboTypeId = 'vabo_type_id',
}
