import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Container, FilterBar, useSnackbar } from '@sprnova/nebula';
import { useGetClientPublicQuery } from 'api/crudGraphQL/public/clients/getClient';
import { useCreateLookerUrlPublicMutation } from 'api/crudGraphQL/public/looker/createLookerUrlPublic';
import { useGetMePublicQuery } from 'api/crudGraphQL/public/me/getMePublic';
import { novaGraphQLPublicClient } from 'api/entityGraphQL/NovaGraphQLPublicClient';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import { LookerReportPageContent } from 'features/clients/components';
import LookerReportSelect, { FavoritedReport } from 'features/clients/components/LookerReportSelect/LookerReportSelect';
import { getCategoriesWithCombinedReports } from 'features/clients/components/LookerReportSelect/utils';
import { LookerReportTypeEnum } from 'features/clients/constants';
import { isClientBusinessTypeLookerReport } from 'features/clients/utils';
import { LookerBaseReport, LookerCategory, LookerCustomReport, LookerStandardReport } from 'features/entitiesRedux/models/client/client';
import { LookerUserFavorite } from 'features/entitiesRedux/models/looker_user_favorites/looker_user_favorite';
import { NovaGptBanner } from './components';
import { getReportByName } from './utils';
import NovaGptButton from '../NovaGptButton/NovaGptButton';
import css from './LookerReportPage.module.scss';

type LookerReportPageType = {
  reportType: LookerReportTypeEnum;
  lookerCategories: LookerCategory[];
  /**
   * The Looker ID of the report that is to be preselected.
   */
  initialReport?: string;
}

const LookerReportPage = ({ reportType, lookerCategories, initialReport }: LookerReportPageType): JSX.Element => {
  const { addSnackbar } = useSnackbar();
  const { clientId: idRaw } = useParams<{ [x: string]: string }>();

  const [error, setError] = useState<string>();
  const [isLoadingFavorites, setIsLoadingFavorites] = useState(false);
  const [isUpdateFavoriteFinished, setIsUpdateFavoriteFinished] = useState<boolean>(true);
  const [lookerFavoritesData, setLookerFavoritesData] = useState<LookerUserFavorite[]>();
  const [lookerReportId, setLookerReportId] = useState<string | undefined>(initialReport);
  const [signedUrl, setSignedUrl] = useState<string>();
  const [starred, setStarred] = useState<FavoritedReport[]>([]);

  const [createLookerUrlRequest, { isLoading: isLoadingDashboard }] = useCreateLookerUrlPublicMutation();
  const { data: client, isLoading: isLoadingClient } = useGetClientPublicQuery({
    id: parseInt(idRaw),
    projection: {
      business_type: {
        id: true,
        slug: true,
      },
      id: true,
      name: true,
    }
  }, { skip: !parseInt(idRaw) });
  const mixpanel = useMixpanel();
  const { data: externalUser } = useGetMePublicQuery({
    projection: {
      id: true,
      name: true,
      email: true,
      is_pdm_employee: true,
    }
  });

  // Check the URL to identify the workflow.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportId = Number(queryParams.get('reportId'));

  const selectedReport = useMemo(() =>
    lookerReportId !== undefined
      ? getReportByName(lookerCategories, lookerReportId)
      : null,
  [lookerCategories, lookerReportId]);

  /** Mixpanel Analytics */
  const handleTrackMixpanelEvent = useCallback((reportName: string, displayName?: string ): void => {
    // track "Mixpanel" Dropdown select click event
    try {
      const options = {
        clientId: client?.id,
        clientName: client?.name,
        externalUserId: externalUser?.id,
        externalUserName: externalUser?.name,
        externalUserEmail: externalUser?.email,
        isPdmEmployee: externalUser?.is_pdm_employee,
        reportName,
        displayName
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Looker Report Dropdown Selection clicked', options);
      if (mixpanel?.track) {
        mixpanel.track('Looker Report - Click', options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[client?.id, client?.name, externalUser?.email, externalUser?.id, externalUser?.is_pdm_employee, externalUser?.name, mixpanel]);

  /** Favorite Mixpanel Analytics */
  const handleTrackMixpanelFavoriteEvent = useCallback((favorite: FavoritedReport ): void => {
    // track "Mixpanel" Favorite select click event
    try {
      const options = {
        clientId: client?.id,
        clientName: client?.name,
        externalUserId: externalUser?.id,
        externalUserName: externalUser?.name,
        externalUserEmail: externalUser?.email,
        isPdmEmployee: externalUser?.is_pdm_employee,
        favoriteId: favorite.id,
        favoriteType: favorite.type
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Looker Report Favorite clicked', options);
      if (mixpanel?.track) {
        mixpanel.track('Favorite Report', options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[client?.id, client?.name, externalUser?.email, externalUser?.id, externalUser?.is_pdm_employee, externalUser?.name, mixpanel]);

  const handleTrackMixpanelAskGPTEvent = useCallback((): void => {
    try {
      const options = {
        clientId: client?.id,
        clientName: client?.name,
        accountId: externalUser?.id,
        accountName: externalUser?.name,
        accountEmail: externalUser?.email,
        isPdmEmployee: externalUser?.is_pdm_employee,
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Access novaGPT - Client Report Page', options);
      if (mixpanel?.track) {
        mixpanel.track('Access novaGPT - Client Report Page', options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[client?.id, client?.name, externalUser?.email, externalUser?.id, externalUser?.is_pdm_employee, externalUser?.name, mixpanel]);

  const didTrackInitialReportSelect = useRef<boolean>(false);
  useEffect(() => {
    if(initialReport && client && externalUser && !didTrackInitialReportSelect.current) {
      handleTrackMixpanelEvent(selectedReport?.looker_name as string, selectedReport?.display_name);
      didTrackInitialReportSelect.current = true;
    }
  }, [handleTrackMixpanelEvent, didTrackInitialReportSelect, initialReport, selectedReport?.display_name, selectedReport?.looker_name, client, externalUser]);

  useEffect(() => {
    if (reportId) {
      if (reportType === LookerReportTypeEnum.Base) {
        const matchingReport = lookerCategories.reduce((acc: LookerBaseReport | null, category) => {
          if (acc) return acc;
          return category.looker_base_reports.find((report) => report.id === reportId) || null;
        }, null);
        if (matchingReport) setLookerReportId(matchingReport?.looker_name);
      }
      if (reportType === LookerReportTypeEnum.Custom) {
        const matchingReport = lookerCategories.reduce((acc: LookerCustomReport | null, category) => {
          if (acc) return acc;
          return category.looker_custom_reports.find((report) => report.id === reportId) || null;
        }, null);
        if (matchingReport) setLookerReportId(matchingReport?.looker_name);
      }
      if (reportType === LookerReportTypeEnum.Standard) {
        const matchingReport = lookerCategories.reduce((acc: LookerStandardReport | null, category) => {
          if (acc) return acc;
          return category.looker_standard_reports.find((report) => report.id === reportId) || null;
        }, null);
        if (matchingReport) setLookerReportId(matchingReport?.looker_name);
      }
    }
  }, [lookerCategories, reportId, reportType]);

  useEffect(() => {
    const lookerUserFavoritesData = async () : Promise<void> => {
      try {
        if(client?.id){
          setIsLoadingFavorites(true);

          const favorites = await novaGraphQLPublicClient.getLookerUserFavorites({
            client_id: client.id,
            projection: {
              id: true,
              report_id: true,
              report_type: true,
            }
          });

          const starredValues: FavoritedReport[] = favorites.map(({ report_id, report_type }) => {
            const id = report_id;
            report_type = report_type.charAt(0).toUpperCase() + report_type.substring(1);
            const type = LookerReportTypeEnum[report_type as keyof typeof LookerReportTypeEnum];

            if (type !== undefined) {
              return {
                id,
                type,
              };
            }

            throw new Error(`Failed to index into LookerReportTypeEnum with key ${report_type}.`);
          });

          setLookerFavoritesData(favorites);
          setStarred(starredValues);
        }
      }
      catch(error){
        console.error('Could not fetch favorites data');
        setError(error);
      } finally {
        setIsLoadingFavorites(false);
      }

    };
    lookerUserFavoritesData();
  },[externalUser?.id, client?.id, lookerCategories, reportType, isUpdateFavoriteFinished]);

  useEffect(() => {
    const getSignedURL = async (): Promise<void> => {
      const errorMessage = 'Error loading Looker dashboard. Please try again.';
      if ( client?.id && lookerReportId) {
        try {
          const resultAction = await createLookerUrlRequest({client_id: client.id, dashboard: lookerReportId}).unwrap();
          const signedUrl = resultAction?.signed_url;
          if (signedUrl) {
            setSignedUrl(signedUrl);
          } else {
            setError(errorMessage);
          }
        } catch (error) {
          console.error('Error creating signed url', error);
          setError(errorMessage);
        }
      }
    };
    getSignedURL();
  }, [client?.id, createLookerUrlRequest, lookerReportId]);

  const handleFavorite = useCallback(async (favorite: FavoritedReport) => {
    try {
      setIsUpdateFavoriteFinished(false);

      if (client?.id === undefined) {
        throw new Error('Unknown client ID.');
      }

      await novaGraphQLPublicClient.saveLookerFavorite({
        client_id: client?.id,
        report_id: favorite.id,
        report_type: favorite.type,
      });
      handleTrackMixpanelFavoriteEvent(favorite);
      setStarred((favorites) => [...favorites, favorite]);
    } catch {
      console.error('Failed to update favorites');
      addSnackbar({
        variant: 'error',
        message: 'Failed to update favorites',
      });
    } finally {
      setIsUpdateFavoriteFinished(true);
    }
  }, [addSnackbar, client?.id, handleTrackMixpanelFavoriteEvent]);

  const handleUnfavorite = useCallback(async ({ id, type }: FavoritedReport) => {
    try {
      setIsUpdateFavoriteFinished(false);

      const lookerFavoriteId = lookerFavoritesData?.find((favorite) =>
        favorite.report_id === id
        && favorite.report_type.toLocaleLowerCase() === type.toLocaleLowerCase())?.id;

      if (lookerFavoriteId === undefined) {
        throw new Error('Unknown lookerFavoriteId.');
      }

      await novaGraphQLPublicClient.deleteLookerFavorite({
        id: lookerFavoriteId,
      });

      setStarred((favorites) => {
        const removeIndex = favorites.findIndex((favorite) => favorite.id === id && favorite.type === type);
        return [
          ...favorites.slice(0, removeIndex),
          ...favorites.slice(removeIndex + 1),
        ];
      });
    } catch {
      console.error('Failed to update favorites');
      addSnackbar({
        variant: 'error',
        message: 'Failed to update favorites',
      });
    } finally {
      setIsUpdateFavoriteFinished(true);
    }
  }, [addSnackbar, lookerFavoritesData]);

  const handleChange = useCallback((event: SelectChangeEvent<unknown>) => {
    setError('');
    const reportName = event.target.value;
    const displayName = selectedReport?.display_name ?? '';
    setLookerReportId(reportName as string);
    handleTrackMixpanelEvent(reportName as string, displayName);
  }, [handleTrackMixpanelEvent, selectedReport?.display_name]);

  const reportsFilter = useCallback((report: LookerStandardReport) => {
    let result: boolean;

    if (client !== undefined && (reportType === LookerReportTypeEnum.Base || reportType === LookerReportTypeEnum.Standard)) {
      result = isClientBusinessTypeLookerReport(client, report);
    } else {
      // Include all reports.
      result = true;
    }

    return result;
  }, [client, reportType]);

  const categoriesWithCombinedReports = useMemo(() =>
    getCategoriesWithCombinedReports(lookerCategories, reportsFilter),
  [lookerCategories, reportsFilter]);

  const reportTypeTitle = reportType === LookerReportTypeEnum.Base ? 'Reports' : reportType === LookerReportTypeEnum.Custom ? 'Custom Reports' : reportType === LookerReportTypeEnum.Standard ? 'Insights AI' : reportType;

  const reportTypeLabel = reportType === LookerReportTypeEnum.Base ? 'Report' : reportType === LookerReportTypeEnum.Custom ? 'Custom Report' : reportType === LookerReportTypeEnum.Standard ? 'Insights AI' : reportType;

  const REPORT_TYPE_DESCRIPTION: Record<LookerReportTypeEnum, string> = {
    base: 'nova reports are where insightful data meets intuitive design to empower your decision-making process.',
    standard: 'nova Insights AI is an advanced analytics tool within Power Digital\'s nova ecosystem that combines marketing reports, AI-driven analytics, and access to data experts, all powered by proprietary machine learning algorithms and a custom-built GPT.',
    custom: 'nova Custom Reports are bespoke solutions to offer flexible, actionable insights tailored to meet your organization\'s unique needs.',
  };

  const reportTypeDescription = reportType === LookerReportTypeEnum.Base ? REPORT_TYPE_DESCRIPTION.base : reportType === LookerReportTypeEnum.Custom ? REPORT_TYPE_DESCRIPTION.custom : reportType === LookerReportTypeEnum.Standard ? REPORT_TYPE_DESCRIPTION.standard : undefined;
  const isStandardReport = reportType === LookerReportTypeEnum.Standard;

  const renderNovaGptButton = useMemo(() => {
    const button = <NovaGptButton handleMixPanelEvent={handleTrackMixpanelAskGPTEvent} size={'large'}/>;
    return (
      <Box ml={5}>
        {button}
      </Box>
    );
  }, [handleTrackMixpanelAskGPTEvent]);

  return (
    <div className={css.root} style={{backgroundColor: signedUrl && !isLoadingDashboard ? 'rgb(247 248 250)' : 'white' }}>
      <PageHero title={reportTypeTitle} description={reportTypeDescription} end={renderNovaGptButton} hideHome>
        <FilterBar
          title='Select a report'
          variant="combo-box-selection">
          <LookerReportSelect
            categories={categoriesWithCombinedReports}
            value={lookerReportId}
            favoritedReports={starred}
            id={`${reportType}-report-select`}
            label={reportTypeLabel}
            onChange={handleChange}
            onFavorite={handleFavorite}
            onUnfavorite={handleUnfavorite}
            skeleton={isLoadingFavorites || isLoadingClient}
          />
        </FilterBar>
      </PageHero>
      <Container className={css.container} hasVerticalPadding>
        <LookerReportPageContent
          dashboardUrl={signedUrl}
          documentationUrl={selectedReport?.documentation_url}
          hasReports={categoriesWithCombinedReports?.length > 0}
          error={error}
          isExternal
          isLoading={isLoadingDashboard || isLoadingFavorites || isLoadingClient}
          reportType={reportType}
        />
      </Container>
      {isStandardReport ? <NovaGptBanner client={client} /> : <></>}
    </div>
  );
};

export default memo(LookerReportPage);
