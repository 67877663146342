import React, { Dispatch, ForwardedRef, forwardRef, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, ThumbsDownIcon, ThumbsUpIcon, Typography, ThumbsUpFilledIcon, ThumbsDownFilledIcon, CaretOutlinedLeftIcon, CaretOutlinedRightIcon, IconButton, Tooltip, CopyIcon, CheckIcon, HelpIcon, Link, Button, ButtonBar } from '@sprnova/nebula';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { useGetClientPublicQuery } from 'api/crudGraphQL/public/clients/getClient';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import isExternalUser from 'utils/helpers/isExternalUser';
import { useNovaAITier } from 'features/clients/hooks';
import { NovaAIFeedbackReasonsType, NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { ExternalUser, User } from 'features/entitiesRedux/models/user';
import { FeedbackType, PromptIdentifierType } from '../../NovaGpt';
import ChatBubble from '../ChatBubble';
import css from '../../NovaGpt.module.scss';


type NovaGptFeedbackType = {
  account: User | ExternalUser | null;
  clientId: number;
  answer: NovaAIResponseType[];
  handlePrevious: () => void;
  handleNext: (answerLength: number) => void;
  answerIndex: number;
  activeThumbsUp: (PromptIdentifierType | undefined)[];
  activeThumbsDown: (PromptIdentifierType | undefined)[];
  optionalFeedback: NovaAIFeedbackReasonsType[];
  handleOptionalFeedback: (feedback: NovaAIFeedbackReasonsType, promptGuid: string, version: number) => void;
  handleFeedback: (feedbackType: FeedbackType, promptGuid: string, version: number) => void;
  handleRefresh: () => void;
  currentPrompt: PromptIdentifierType;
  isLoading: boolean;
  showHelpFeedback?: boolean;
  setshowHelpFeedback?: Dispatch<SetStateAction<boolean>>;
  showOptionalFeedback?: boolean;
  showSupportRequestConfirmation?: boolean;
  showAnalystReviewConfirmation?: boolean;
  setShowAnalystReviewConfirmation?: Dispatch<SetStateAction<boolean>>
  handleNoOptionalFeedback: (feedback: NovaAIFeedbackReasonsType, promptGuid: string, version: number) => void;
  toggleHelpCenterModal: () => void;
  trackHelpCenterMixpanel: (buttonClicked: string) => void;
}

const disableCursor = { cursor:'not-allowed', pointerEvents: 'none' };

const helpResponses = [
  {name: 'How do I ask a question?', link: 'https://help.novapower.io/en/articles/9376739-nova-insights-ai-faq'},
  {name: 'How should I format my question for best results?', link: 'https://help.novapower.io/en/articles/9736314-how-to-talk-to-your-data-nova-insights-ai'},
  {name: 'What topics can I ask about?', link: 'https://help.novapower.io/en/articles/9400632-nova-gpt-questions-to-ask-by-topic'}
];

const NovaGptFeedback = forwardRef(({
  account,
  answer,
  clientId,
  handlePrevious,
  handleNext,
  answerIndex,
  activeThumbsUp,
  activeThumbsDown,
  optionalFeedback,
  handleOptionalFeedback,
  handleFeedback,
  currentPrompt,
  isLoading,
  showHelpFeedback,
  showOptionalFeedback = false,
  showSupportRequestConfirmation = false,
  showAnalystReviewConfirmation = false,
  setShowAnalystReviewConfirmation,
  handleNoOptionalFeedback,
  toggleHelpCenterModal,
  trackHelpCenterMixpanel
}: NovaGptFeedbackType, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const [selectedNegativeOption, setSelectedNegativeOption] = useState<NovaAIFeedbackReasonsType | null>(null);

  const mixpanel = useMixpanel();
  const isExternal = isExternalUser();

  const { data: internalClient } = useGetClientQuery({
    id: clientId || 0,
    projection: { id: true, name: true }
  }, { refetchOnMountOrArgChange: true, skip: isExternal });

  const { data: externalClient } = useGetClientPublicQuery({
    id: clientId,
    projection: {
      id: true,
      name: true,
    }
  }, { skip: !clientId || !isExternal});

  const { aiTier } = useNovaAITier({ client_id: clientId }, !clientId);

  const feedbackSelected = useMemo(() => activeThumbsUp?.some(obj =>
    obj?.promptGuid === currentPrompt?.promptGuid && obj?.version === currentPrompt?.version) ||
    activeThumbsDown?.some(obj => obj?.promptGuid === currentPrompt?.promptGuid && obj?.version === currentPrompt?.version)
  ,[activeThumbsDown, activeThumbsUp, currentPrompt.promptGuid, currentPrompt.version]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(answer[answerIndex]?.response ?? '');
    setCopied(true);
  }, [answer, answerIndex]);

  useEffect(() => {
    let timeoutId: number;
    if (copied) {
      timeoutId = window.setTimeout(() => setCopied(false), 2000);
    }
    return ():void => clearTimeout(timeoutId);
  }, [copied]);

  /** Mixpanel Analytics */
  const handleTrackMixpanelEvent = useCallback((buttonClicked: string): void => {
    try {
      const options = {
        clientId: isExternal ? externalClient?.id : internalClient?.id,
        clientName: isExternal ? externalClient?.name : internalClient?.name,
        accountId: account?.id,
        accountName: account?.name,
        accountEmail: account?.email,
        buttonClicked
      };
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${isExternal ? 'External' : 'Internal'} nova AI Feedback ${buttonClicked} Clicked`, options);
      if (mixpanel?.track) {
        mixpanel.track(`${isExternal ? 'External' : 'Internal'} nova AI ${buttonClicked} Button Clicked`, options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[isExternal, externalClient?.id, externalClient?.name, internalClient?.id, internalClient?.name, account?.id, account?.name, account?.email, mixpanel]);

  const iconButtonStyle = feedbackSelected || isLoading ? disableCursor : {};

  const showFilledThumbCondition = (activeThumb: PromptIdentifierType | undefined): boolean => {
    return activeThumb?.promptGuid === currentPrompt.promptGuid && activeThumb?.version === currentPrompt.version;
  };

  return (
    <div className={css.feedbackContainer} ref={ref}>
      {currentPrompt &&
        <>
          {showOptionalFeedback && optionalFeedback.length > 0 ? (
            showAnalystReviewConfirmation ? (
              <ChatBubble variant="light">
                <Typography>Would you like a nova analyst to review your question and contact you with the answer?</Typography>
                <ButtonBar
                  sx={{mt: 1}}
                  primaryButtonProps={{ children: 'Yes, get analyst support', onClick: () => {handleOptionalFeedback({ id: 'other', name: 'Other', slug: 'other' } as unknown as NovaAIFeedbackReasonsType, currentPrompt.promptGuid, currentPrompt.version || 0); setShowAnalystReviewConfirmation?.(false); }}}
                  secondaryButtonProps={{ children: 'No, thank you', onClick: () => {handleNoOptionalFeedback({ id: selectedNegativeOption?.id, name: selectedNegativeOption?.name, slug: selectedNegativeOption?.slug } as unknown as NovaAIFeedbackReasonsType, currentPrompt.promptGuid, currentPrompt.version || 0); setShowAnalystReviewConfirmation?.(false); }}}
                  alignment="start"
                  size="small"
                />
              </ChatBubble>
            ) :
              (
                <ChatBubble variant="light">
                  <Typography>Please tell us more about your experience:</Typography>
                  {optionalFeedback.map((feedback, key) => (
                    <div key={key}>
                      <Chip
                        sx={{mt: 1}}
                        clickable
                        label={feedback?.name}
                        onClick={() => {setSelectedNegativeOption(feedback); handleOptionalFeedback(feedback, currentPrompt.promptGuid, currentPrompt.version || 0);}}
                      />
                    </div>
                  ))}
                </ChatBubble>)
          ) : null}
          {showHelpFeedback ? (
            <ChatBubble variant="light">
              <Typography sx={{pb: 2}} variant='h4'>novaGPT Support</Typography>
              <div>
                FAQs
              </div>
              {helpResponses.map((helpResponse, key) => (
                <div key={key}>
                  <Chip
                    sx={{mt: 1}}
                    clickable
                    label={helpResponse?.name}
                    onClick={() => window.open(helpResponse?.link, '_blank')}
                  />
                </div>
              ))}

              {aiTier?.slug !== 'essential' &&  <Button sx={{mt: 2}} size="small" variant="primary"
                onClick={() => handleOptionalFeedback({ id: 'contact_analyst', name: 'Contact Analyst', slug: 'contact_analyst' } as unknown as NovaAIFeedbackReasonsType, currentPrompt.promptGuid, currentPrompt.version || 0)}
              >
                Contact an analyst to help me with my prompt
              </Button>}
            </ChatBubble>
          ) : null}
          {showSupportRequestConfirmation && (
            <ChatBubble variant="light">
              Our analyst team has been contacted about your support request and will work on getting an answer to you in 1-3 business days. You&apos;ll receive an email with their results.
            </ChatBubble>
          )}
          <div className={css.answerFooter} style={{display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {copied ? (
                <Tooltip content="Copied!" variant="plain">
                  <IconButton variant="plain" size="xs">
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip content="Copy" variant="plain">
                  <IconButton onClick={() => {
                    handleTrackMixpanelEvent('Copy');
                    copyToClipboard();
                  }} variant='plain' size='xs'>
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              )}
              {activeThumbsUp.some(obj => showFilledThumbCondition(obj)) ?
                <IconButton sx={iconButtonStyle} variant='plain' size='xs'>
                  <ThumbsUpFilledIcon
                    className={css.disabledThumb}
                  />
                </IconButton>
                :
                <Tooltip content="Good Response" variant="plain">
                  <IconButton
                    sx={iconButtonStyle}
                    onClick={() => {
                      handleTrackMixpanelEvent('Thumbs Up');
                      handleFeedback(FeedbackType.positive, currentPrompt.promptGuid, currentPrompt.version || 0);
                    }}
                    variant='plain'
                    size='xs'
                  >
                    <ThumbsUpIcon/>
                  </IconButton>
                </Tooltip>
              }
              {activeThumbsDown.some(obj => showFilledThumbCondition(obj)) ?
                <IconButton sx={iconButtonStyle} variant='plain' size='xs'>
                  <ThumbsDownFilledIcon
                    className={css.disabledThumb}
                  />
                </IconButton>
                :
                <Tooltip content="Bad Response" variant="plain">
                  <IconButton sx={iconButtonStyle}
                    onClick={() => {
                      handleTrackMixpanelEvent('Thumbs Down');
                      handleFeedback(FeedbackType.negative, currentPrompt.promptGuid, currentPrompt.version || 0);
                    }}
                    variant='plain'
                    size='xs'
                  >
                    <ThumbsDownIcon/>
                  </IconButton>
                </Tooltip>
              }
              <Tooltip content={<Link sx={{':hover': { color: 'white'}}} color='white'>Help Center</Link>} variant="plain">
                <IconButton onClick={() => {
                  trackHelpCenterMixpanel('Help Center Button');
                  toggleHelpCenterModal();
                }} variant='plain' size='xs'>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              {/* TODO: un-hide regenerate button when ready */}
              {/* <Tooltip content="Regenerate" variant="plain">
                <IconButton sx={isLoading ? disableCursor : {}} onClick={() => handleRefresh()} variant='plain' size='xs'>
                  <RefreshIcon />
                </IconButton>
              </Tooltip> */}
            </div>
            {answer.length > 1 &&
              <div style={{display: 'flex', fontSize: '12px'}}>
                <IconButton size="xs" variant="plain" disabled={answerIndex === 0} onClick={() => handlePrevious()}>
                  <CaretOutlinedLeftIcon/>
                </IconButton>
                <div style={{margin: '0 2px 0 0'}}>
                  {answerIndex + 1}/{answer.length}
                </div>
                <IconButton size="xs" variant="plain" disabled={answerIndex === answer.length - 1} onClick={() => handleNext(answer.length)}>
                  <CaretOutlinedRightIcon/>
                </IconButton>
              </div>
            }
          </div>
        </>
      }
    </div>
  );
});

NovaGptFeedback.displayName = 'NovaGptFeedback';

export default memo(NovaGptFeedback);
