import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '@sprnova/nebula';
import classNames from 'classnames';
import { Strategy } from 'features/entitiesRedux';
import css from './BlueprintName.module.scss';

type BlueprintNameProps = {
  blueprint: Strategy;
  className?: string;
};

const BlueprintName = ({ blueprint, className }: BlueprintNameProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.stopPropagation();
  };

  return (
    <div className={classNames(css.root, className)}>
      <Avatar title={blueprint?.client?.name} src={blueprint?.client?.logo} alt="Client avatar">
        {blueprint?.client?.name?.charAt(0) || '-'}
      </Avatar>
      <div>
        <div><b>{blueprint?.name}</b></div>
        <Link to={`/power-view/accounts/${blueprint?.client?.id}`} target="_blank" rel="noopener noreferrer" className={css.client__link} onClick={(event): void => handleClick(event)}>Client ID: {blueprint?.client?.id}</Link>
      </div>
    </div>
  );
};

export default memo(BlueprintName);
