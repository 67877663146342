import React, { memo } from 'react';
import { Box, Button, DrawerHeader, InfoTooltip, Typography } from '@sprnova/nebula';
import { GetNovaChallengesResult } from 'api/crudGraphQL/nova_ai/getNovaChallenges';
import { NovaAiChallengeType } from 'features/entitiesRedux/models/nova_ai';
import UsageStepper from '../UsageStepper';
import css from '../../NovaGpt.module.scss';

type NovaGptHeaderProps = {
    closeDrawer?: () => void;
    toggleHelpCenterModal: () => void;
    trackHelpCenterMixpanel: (description: string) => void;
    challengeData?: GetNovaChallengesResult;
    currentChallenge?: NovaAiChallengeType;
    isDrawer?: boolean;
}

const NovaGptHeader = ({closeDrawer, toggleHelpCenterModal, trackHelpCenterMixpanel, challengeData, currentChallenge, isDrawer}: NovaGptHeaderProps): JSX.Element => {
  return (
    <>
      <DrawerHeader
        title={
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography variant='h2'>
              Ask novaGPT
              </Typography>
              <Typography variant='subtitle2' className={css.subtitle}>
              v1.0
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <InfoTooltip
                iconSize="small"
                heading="Need assistance with novaGPT?"
                content="Browse links, FAQs and other help resources"
                variant='plain'
                placement='bottom-end'
                sx={{cursor: 'default'}}
              />
              <Button onClick={() => {toggleHelpCenterModal(); trackHelpCenterMixpanel('Help Center Button');}}>Help</Button>
            </Box>
          </Box>
        }
        {...(isDrawer && {
          closeLabel: 'Close',
          onClose: closeDrawer,
        })}
        className={css.header}
      />
      {(challengeData?.length && currentChallenge) ? <UsageStepper steps={challengeData} currentStep={currentChallenge} /> : <></>}
    </>
  );
};

export default memo(NovaGptHeader);
