import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableCell, TablePagination, TableRow, useTheme, Paper } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { Skeleton, IconButton, CaretOutlinedRightIcon, CaretOutlinedLeftIcon, Button } from '@sprnova/nebula';
import { EntitiesListProps } from 'components/List/List';
import omitBy from 'lodash/omitBy';
import Order from 'utils/sort/Order';
import { capitalize, formatCurrency } from 'utils';
import { Strategy } from 'features/entitiesRedux';
import BlueprintName from 'features/strategies/components/BlueprintName';
import BlueprintStatus from 'features/strategies/components/BlueprintStatus';
import { BLUEPRINT_REVIEWER_TYPE } from 'features/strategies/constants';
import { Alert } from 'components';
import { EnhancedTableHead } from './components';
import HeadCellType from './components/HeadCellType';
import css from './BlueprintsPackageReviewListTable.module.scss';

type BlueprintsPackageReviewListTableTypes = EntitiesListProps & {
  error?: any;
  filter: Record<string, unknown>;
  isLoading?: boolean;
  strategies: Strategy[];
  strategiesTotal: number;
  reviewerType: string;
};

export type KeyStrategy = keyof Strategy;
type AnyObject = { [key: string]: any };

/**
 * Component to render the table list of blueprints package that need to be reviewed
 */
const BlueprintsPackageReviewListTable: FC<BlueprintsPackageReviewListTableTypes> = ({
  error,
  filter,
  isLoading,
  strategies,
  strategiesTotal,
  reviewerType
}) => {
  const hasFilters = !!Object.keys(omitBy(filter, (val) => !val)).length;
  const loading = isLoading || (strategies?.length === 0 && !hasFilters);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<KeyStrategy>('name');
  const [isNumeric, setIsNumeric] = useState<boolean>(false);
  const hashRoute = reviewerType === BLUEPRINT_REVIEWER_TYPE.Department ? '#team-commissions' : '';
  const history = useHistory();

  /* TABLE Functions */

  const TablePaginationActions = (props: TablePaginationActionsProps): JSX.Element => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const theme = useTheme();

    const handleBackButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    }, [onPageChange, page]);

    const handleNextButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    }, [onPageChange, page]);

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <CaretOutlinedRightIcon /> : <CaretOutlinedLeftIcon />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <CaretOutlinedLeftIcon /> : <CaretOutlinedRightIcon />}
        </IconButton>
      </Box>
    );
  };

  const handleRequestSort = useCallback((
    event: React.MouseEvent<unknown>,
    property: KeyStrategy,
    isNumeric: boolean,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setIsNumeric(isNumeric);
  }, [order, orderBy]);

  const headCells: Partial<HeadCellType>[] = useMemo(() => [
    {
      id: 'blueprint-name',
      label: 'Blueprint Name',
      disablePadding: false,
      isNumeric: false,
      property: 'name',
      sort: true,
    },
    {
      id: 'start-date',
      label: 'Start Date',
      disablePadding: false,
      isNumeric: false,
    },
    {
      id: 'end-date',
      label: 'End Date',
      disablePadding: false,
      isNumeric: false,
    },
    {
      id: 'blueprint-stage',
      label:
      <span>
        Stage
      </span>,
      disablePadding: false,
      isNumeric: false

    },
    {
      id: 'average-gp',
      label: 'Avg. GP',
      disablePadding: false,
      isNumeric: true
    },
    {
      id: 'total-revenue',
      label: 'Total Revenue',
      disablePadding: false,
      isNumeric: true
    },
    {
      id: 'type',
      label: 'Type',
      disablePadding: false,
      isNumeric: false
    },
    {
      id: 'length-of-contract',
      label: 'Length of Contract',
      disablePadding: false,
      isNumeric: true,
    },
    {
      id: 'action',
      label: '',
      disablePadding: false,
      isNumeric: false
    },
  ], []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = useCallback((
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  }, []);

  /**
   * Sort the strategies by the orderBy and order
   * Separate sort for:
   * - strings sort
   * - numerical sort
   */
  const handleSort = useCallback(() => {
    const sortedList = [...strategies];
    if (isNumeric) {
      if (order === 'asc') return sortedList.sort((blueprintA: AnyObject, blueprintB: AnyObject) => blueprintA?.[orderBy] - blueprintB?.[orderBy]);
      if (order === 'desc') return sortedList.sort((blueprintA: AnyObject, blueprintB: AnyObject) => blueprintB?.[orderBy] - blueprintA?.[orderBy]);
    } else {
      if (order === 'asc') return sortedList.sort((blueprintA: AnyObject, blueprintB: AnyObject) => blueprintA?.[orderBy]?.localeCompare(blueprintB?.[orderBy]));
      if (order === 'desc') return sortedList.sort((blueprintA: AnyObject, blueprintB: AnyObject) => blueprintB?.[orderBy]?.localeCompare(blueprintA?.[orderBy]));
    }
  }, [isNumeric, order, orderBy, strategies]);

  /**
   * Slice the list of Blueprints to display only the rowsPerPage value
   * @param {Strategy[] | []} - List of Blueprints
   * @returns {Strategy[] | []} List of Blueprints
   */
  const sliceList = useCallback((list: Strategy[] | []): Strategy[] | [] => {
    const slicedList = list.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    if (slicedList.length <= 0 && page > 0) { // if the list is empty (can happen when changing rows per page value), go back a page
      setPage((prevValue) => prevValue - 1);
      return list.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage,
      );
    } else { // else stay on the same page
      return slicedList;
    }
  }, [page, rowsPerPage]);

  const visibleRows = useMemo(
    () => {
      const sortedList = handleSort() ?? [];
      return sliceList(sortedList);
    }, [handleSort, sliceList]);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);
  /* END table Functions */

  const tableContainer = useMemo(() => <TableContainer component={Paper} className={css.table__container}>
    <Table aria-label="custom pagination table">
      <EnhancedTableHead
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        className={css.table__header}
      />
      <TableBody className={css.table__body}>
        {visibleRows.map((blueprint: Strategy) => {
          const blueprintDate = new Date(blueprint.date);
          const dateOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          };
          return (
            <>
              <TableRow component={Link} to={`/blueprints/package/${blueprint?.id}`} sx={{ height: '110px' }} className={css.table__row} key={blueprint.id}>
                <TableCell style={{ width: 200, minWidth: 200 }}>
                  <BlueprintName blueprint={blueprint} />
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {new Date(blueprint.date).toLocaleDateString('en-US', dateOptions)}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {new Date(blueprintDate.setMonth(blueprintDate.getMonth()+ blueprint.length)).toLocaleDateString('en-US', dateOptions)}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  <BlueprintStatus className={css.table__body__cell__status} status={blueprint.status} />
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {blueprint?.monthly_gross_profit ? formatCurrency(blueprint?.monthly_gross_profit) : '-'}
                </TableCell>
                <TableCell style={{ width: 250 }}>
                  {blueprint?.total_retainer ? formatCurrency(blueprint?.total_retainer) : '-'}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {capitalize(blueprint.type)}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {`${blueprint.length} Month${blueprint.length > 1 ? 's' : ''}`}
                </TableCell>
                <TableCell style={{ width: 160 }} onClick={(event) => event.preventDefault()}>
                  <Button
                    className={css.link}
                    disableRipple
                    onClick={() => history.push(`/blueprints/package/${blueprint?.id}${hashRoute}`, {from: 'internal'})}
                    size="small"
                  >
                    Review Blueprint
                  </Button>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  , [handleRequestSort, hashRoute, headCells, history, order, orderBy, visibleRows]);

  const tablePagination = useMemo(() => {
    return (
      <div className={css.table__pagination}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={strategiesTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={memo(TablePaginationActions)}
        />
      </div>
    );
  }, [strategiesTotal, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage]);

  /**
   * Render the blueprint table list
   * @returns JSX.Element
   */
  const renderItem = (): JSX.Element => {
    return (
      <div>
        {loading ?
          <Skeleton>
            {tableContainer}
          </Skeleton>
          :
          <>
            {tableContainer}
            {tablePagination}
          </>
        }
      </div>
    );
  };

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <>
      {renderItem()}
    </>
  );
};

export default memo(BlueprintsPackageReviewListTable);
