import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Control } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Autocomplete, Controller, DatePicker, Grid, MonetaryField, Radio, Select, TextField, Typography, useFieldArray } from '@sprnova/nebula';
import { useGetIndicatorsQuery } from 'api/crudGraphQL/indicators/getIndicators';
import { useGetIndicatorEarlyDateQuery } from 'api/crudGraphQL/scoreboards/indicators/getIndicatorEarlyDate';
import { PacingGoals } from 'api/crudGraphQL/scoreboards/types';
import { add, format } from 'date-fns';
import pluralize from 'pluralize';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';

export type LongTermFormType = {
  metric?: { id: string; label: string };
  goal?: string | number;
  start_date?: Date;
  metrics: { goal: number }[];
  total?: number;
  typeOfGoal?: 'one' | 'month';
};
export type LongTermGoalFormProps = {
  control: Control<LongTermFormType, unknown>;
  trigger: UseFormTrigger<LongTermFormType>
  defaultValues?: Partial<PacingGoals> & {
    data: PacingGoals['data'] | unknown[];
    typeOfGoal?: LongTermFormType['typeOfGoal'];
  };
  setValue?: UseFormSetValue<LongTermFormType>;
};
const LongTermGoalForm = ({ control, trigger, defaultValues, setValue }: LongTermGoalFormProps): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  const [metric, setMetric] = useState<string>(defaultValues?.metric_name || '');
  const [typeOfGoal, setTypeOfGoal] = useState<string>();
  const [startDate, setStartDate] = useState<Date | null>(null);

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'metrics',
  });

  const { data: date, isLoading: dateLoading } = useGetIndicatorEarlyDateQuery({ report_id: parseInt(id) }, { skip: !id });

  useEffect(() => {
    if (defaultValues) {
      remove();
      append(
        defaultValues?.data?.map((item) => ({ goal: item.goal }))
      );
      if (defaultValues?.data?.length > 1) {
        setTypeOfGoal('month');
        setValue?.('typeOfGoal', 'month');
        if (defaultValues.start) setStartDate(new Date(defaultValues.start));
      }
    } else if (!typeOfGoal) {
      setTypeOfGoal('one');
      setValue?.('typeOfGoal', 'one');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, defaultValues, remove, setValue]);

  const { data, isLoading } = useGetIndicatorsQuery({
    goal_pacing: 'yes',
    projection: {
      id: false,
      name: true,
      slug: true,
      format: true,
    },
  });

  const renderInputComponent = useCallback((props) => {
    if (data?.filter((option) => option.name === metric)[0]?.format === '$') {
      return <MonetaryField {...props} />;
    }
    return (
      <TextField
        {...props}
        type='number'
        // Remove functionality that changes the value on scroll
        onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
        InputProps={{ inputProps: { min: 0 } }}
      />
    );
  }, [data, metric]);

  const handleSetTypeOfGoal = useCallback((_: React.ChangeEvent<HTMLInputElement>, value?: string): void => {
    setTypeOfGoal(value);
    setValue?.('typeOfGoal', value as LongTermFormType['typeOfGoal']);
  }, [setValue, setTypeOfGoal]);

  const handleAdd = useCallback((e): void => {
    const diff = fields.length - parseInt(e.target.value);
    // Remove fields based on the value selected
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        remove(parseInt(e.target.value) - 1);
      }
    }
    // Add fields based on the value selected
    if (diff < 0) {
      for (let i = 0; i < Math.abs(diff); i++) {
        append({ goal: 0 });
      }
    }
    // Set total for use in the mutation
    setValue?.('total', parseInt(e.target.value));
  }, [append, fields, remove, setValue]);

  const renderGoalLengthSelect = useMemo(() => {
    return (
      <Grid item xs={12}>
        <Controller
          name='total'
          control={control}
          rules={{ required: 'This is a required field' }}
          render={({ field: { ...field }, fieldState: { error } }): JSX.Element => (
            <Select
              {...field}
              id='total'
              label='Goal length'
              onChange={handleAdd}
              defaultValue={defaultValues?.data?.length}
              error={error?.message}
            >
              {
                Array.from({ length: 12 }, (_, i) => {
                  const title = `${i + 1} ${pluralize('month', i + 1)}`;
                  return <Select.Item key={i} value={i + 1}>{title}</Select.Item>;
                })
              }
            </Select>
          )}
        />
      </Grid>
    );
  }, [control, defaultValues?.data?.length, handleAdd]);

  const renderGoals = useMemo(() => {
    if (typeOfGoal === 'one') {
      return (
        <Grid item xs={12}>
          <Controller
            name='goal'
            control={control}
            rules={{ required: 'This is a required field' }}
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }): JSX.Element => (
              renderInputComponent({
                ...field,
                value,
                error: error?.message,
                onChange: (event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  onChange(event?.target?.value);
                  trigger('metric');
                },
                id: 'CreateNewLongTermGoal-goal',
                label: `Long Term ${metric} Goal`,
              })
            )}
          />
        </Grid>
      );
    }
  }, [typeOfGoal, control, renderInputComponent, metric, trigger]);

  const renderFields = useMemo(() => {
    if (typeOfGoal === 'one' || !startDate || !fields.length) return <></>;
    return (
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ fontWeight: '600' }}>Metric goals by month</Typography>
        </Grid>
        {
          fields.map((field, index) => (
            <Grid item xs={12} key={field.id}>
              <Controller
                name={`metrics.${index}.goal`}
                control={control}
                rules={{ required: 'This is a required field' }}
                render={({
                  field: { onChange, value, ...field },
                  fieldState: { error },
                }): JSX.Element => (
                  renderInputComponent({
                    ...field,
                    value,
                    error: error?.message,
                    onChange: (event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      onChange(event?.target?.value);
                      trigger('metric');
                    },
                    id: 'CreateNewLongTermGoal-goal',
                    label: startDate && format(add(startDate, { months: index }), 'MMMM'),
                  })
                )}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }, [typeOfGoal, startDate, fields, control, renderInputComponent, trigger]);

  const renderStartAndGoals = useMemo(() => {
    return (
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderLeft: '1px solid #6D6D6D',
            marginLeft: '0px',
            padding: '0 20px 16px 14px',
          }}
        >
          <Grid item xs={12}>
            <Controller
              name='start_date'
              control={control}
              rules={{ required: 'This is a required field' }}
              render={({
                field: { onChange, value, ...field },
              }): JSX.Element  => (
                <DatePicker
                  {...field}
                  value={value}
                  defaultValue={defaultValues?.start ? new Date(defaultValues.start) : null}
                  onChange={(date): void => {
                    onChange(date);
                    setStartDate(date);
                    trigger('start_date');
                  }}
                  id='CreateNeLongTermGoal-start-date'
                  label='Start Date'
                  helperText='MM/DD/YYYY'
                  minDate={date && new Date(date) || undefined}
                  disabled={dateLoading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {renderGoalLengthSelect}
          </Grid>
          {renderGoals}
        </Grid>
        {renderFields}
      </Grid>
    );
  }, [control, renderGoalLengthSelect, renderGoals, renderFields, defaultValues?.start, date, dateLoading, trigger]);

  const renderRadioGroup = useMemo(() => {
    if (!metric || !typeOfGoal) return <></>;
    return (
      <Grid item xs={12} padding='24px 0 24px 20px !important'>
        <Radio.Group
          label='How do you want to set the goal(s) up?'
          name='typeOfGoal'
          id='typeOfGoal-radio-group'
          value={typeOfGoal}
          onChange={handleSetTypeOfGoal}
        >
          <Radio value='one' label='One total goal' />
          <Radio value='month' label='Individual goals per month' />
        </Radio.Group>
      </Grid>
    );
  }, [metric, typeOfGoal, handleSetTypeOfGoal]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name='metric'
          control={control}
          rules={{ required: 'This is a required field' }}
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }): JSX.Element => (
            <Autocomplete
              {...field}
              value={typeof value === 'object' && Object.keys(value).length ? value : undefined}
              error={error?.message}
              onChange={(_, newValue): void => {
                onChange(newValue);
                setMetric(newValue?.label);
                trigger('metric');
              }}
              id='CreateNewLongTermGoal-metric'
              label='Metric'
              options={data?.map((option) => ({ label: option.name, id: option.slug })) || []}
              skeleton={!defaultValues && isLoading}
            />
          )}
        />
      </Grid>
      {renderRadioGroup}
      {metric && renderStartAndGoals}
    </Grid>
  );
};

export default memo(LongTermGoalForm);
