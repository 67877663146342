/**
 * Export tag types for each public API service
 */

import clientsTagTypes from './clients/tagTypes';
import homepageWidgetsTagTypes from './homepage_widgets/tagTypes';
import meTagTypes from './me/tagTypes';
import novaFeaturesTagTypes from './nova_features/tagTypes';
import scoreboardsTagTypes from './scoreboards/tagTypes';

export const tagTypes = {
  ...clientsTagTypes,
  ...homepageWidgetsTagTypes,
  ...meTagTypes,
  ...novaFeaturesTagTypes,
  ...scoreboardsTagTypes,
};
