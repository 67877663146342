import React from 'react';
import { useSelector } from 'react-redux';
import { Action, Resource } from 'api/accessControl';
import { useAccessControl, selectAccountMeta } from 'features/global';
import { Warning } from './Warning';

export type AccessControlProps = {
  action: Action | Action[];
  resource: Resource | Resource[];
  authorId?: number;
  children: JSX.Element;
  fallback?: JSX.Element | null;
  unauthorized?: JSX.Element;
  showWarning?: boolean;
  warning?: string;
};
const AccessControl = ({
  action,
  resource,
  authorId,
  children,
  unauthorized,
  fallback = null,
  showWarning = true,
  warning,
  ...props
}: AccessControlProps) => {
  const accountMeta = useSelector(selectAccountMeta);
  const [isLoading] = accountMeta;
  const { can } = useAccessControl();

  const actions = Array.isArray(action) ? action : [action];
  const resources = Array.isArray(resource) ? resource : [resource];
  const isTruthy = (a?: any) => !!a;
  const authorized = actions
    .map((action) =>
      resources
        .map((resource) => can(action, resource, authorId))
        .some(isTruthy)
    )
    .some(isTruthy);

  // console.log("⛱ AccessControl", {
  //   accountMeta,
  //   isLoading,
  //   authorized,
  //   unauthorized,
  //   showWarning,
  //   warning,
  // });

  if (isLoading) {
    return fallback;
  }
  if (authorized) {
    return React.cloneElement(children, { ...props });
  }
  if (unauthorized) {
    return unauthorized;
  }
  if (showWarning || warning) {
    return <Warning message={warning} />;
  }
  return null;
};

export { AccessControl };

/**

<AccessControl
  action={[Action.create]} // required: Action or Action[]
  resource={Resource.client} // required: Resource or Resource[]
  authorId={0} // optional: provide custom authorId for readOwn, editOwn, deleteOwn permissions
  showWarning={false} // optional: hide or show the warning message
  warning="You are not authorized" // optional: custom warning message
  unauthorized={<Redirect to="/" />} // optional: render any <Component /> as a fallback
>
  <Button key="0" type="primary">
    <Link to="/clients/new">
      <PlusCircleOutlined /> New Client
    </Link>
  </Button>
</AccessControl>

 */
