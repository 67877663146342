import { basePublicApi } from '../basePublicApi';
import { Projection } from 'api/entityGraphQL';
import { ExternalUser } from 'features/entitiesRedux';
import tagTypes from './tagTypes';

export type GetMePublicQuery = {
  projection?: Projection;
};

export type GetMePublicResult = ExternalUser;

export const GET_ME_PUBLIC_ENDPOINT_KEY = 'getMePublic';

export const { useGetMePublicQuery, useLazyGetMePublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_ME_PUBLIC_ENDPOINT_KEY]: build.query<GetMePublicResult, GetMePublicQuery>({
      transformResponse: (response: { me: GetMePublicResult }) => response.me,
      providesTags: (me) => {
        return me ? [
          { id: me.id, type: tagTypes.ME },
        ] : [tagTypes.ME];
      },
      query: ({
        projection = { id: true, name: true },
      }) => ({
        body: {
          query: {
            me: {
              ...projection,
            }
          }
        }
      })
    })
  })
});
