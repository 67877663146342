import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Checkbox, Dialog, FilterIcon, Grid, IconButton, Skeleton, Spinner, Stack, Typography, useForm, useSnackbar } from '@sprnova/nebula';
import { useGetScoreboardPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardPublic';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useUpdateScoreboardMutation } from 'api/crudGraphQL/scoreboards/updateScoreboard';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { capitalize } from 'lodash';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';

type EditGoalFiltersProps = {
  isClient: boolean;
  info: string | null;
};
const EditGoalFilters = ({ isClient, info }: EditGoalFiltersProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const [open, toggle] = useReducer((open) => !open, false);
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const { addSnackbar } = useSnackbar();
  const { id } = useParams<{ [x: string]: string }>();

  const buttonStyles = {
    // hide button on client side
    display: isClient ? 'none' : 'block',
  };

  const { data, isLoading } = (isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery)({
    id: parseInt(id),
    projection: {
      id: false,
      goal_name_list: true,
      goal_name_filter: true,
    },
  }, { skip: !id });

  const [updateScoreboardFilter] = useUpdateScoreboardMutation();

  const {
    reset,
    getValues,
    register,
  } = useForm<Record<string, boolean>>();

  const handleApply = useCallback(async () => {
    if (isClient) return;
    const values = [];

    for (const key in getValues()) {
      if (getValues()[key]) {
        values.push(key);
      }
    }
    try {
      setSubmitting(true);
      await updateScoreboardFilter({
        id: parseInt(id),
        goal_name_filter: values,
      });
      addSnackbar({
        message: 'Filters changed',
        variant: 'success',
        persist: false,
      });
      track({ mixpanel, type: 'edit-goal-filters', options: {
        scoreboard_id: id,
        user_id: account?.id,
        user_name: account?.name,
      }});
    } catch (error) {
      console.error(error);
      addSnackbar({
        message: 'Error changing filters',
        variant: 'error',
        persist: false,
      });
    } finally {
      setSubmitting(false);
      toggle();
    }
  }, [account?.id, account?.name, addSnackbar, getValues, id, isClient, mixpanel, updateScoreboardFilter]);

  const handleCancel = useCallback(() => {
    reset();
    toggle();
  }, [reset, toggle]);

  const renderCheckboxes = useMemo(() => {
    if (!data?.goal_name_list) return null;
    if (isLoading) return Array.from({ length: 3 }, (_, i) =>
      <Skeleton
        key={`editgoalfilter-skeleton-${i}`}
        variant='rectangular'
        height={20}
        sx={{ marginBottom: '10px' }}
      />
    );
    return (
      <Stack direction='column' maxWidth={300}>
        {data.goal_name_list?.map((goal: string) => (
          <Checkbox
            key={goal}
            label={capitalize(goal.replace(/_/g, ' '))}
            {...register(goal)}
            defaultChecked={data?.goal_name_filter?.includes(goal)}
            // prevent client from interacting as they are not allowed to change filters
            disabled={isClient}
          />
        ))}
      </Stack>
    );
  }, [data?.goal_name_list, data?.goal_name_filter, isLoading, register, isClient]);

  return (
    <>
      <IconButton size='sm' onClick={toggle}>
        <FilterIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={toggle}
        title='Edit Goal Filters'
        description={
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant='body1'>
                {info}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity='info'>
                Deselecting all filters means &quot;no filter applied&quot; and will return all available data.
              </Alert>
            </Grid>
          </Grid>
        }
        secondaryButtonProps={{
          onClick: handleCancel,
          children: 'Cancel',
          disabled: submitting,
          sx: buttonStyles,
        }}
        primaryButtonProps={{
          onClick: handleApply,
          children: 'Save and Apply',
          disabled: submitting,
          startIcon: submitting && <Spinner size='small' />,
          sx: buttonStyles,
        }}
      >
        <Typography variant='h5' sx={{ fontWeight: 'bold', margin: '10px 0' }}>Available Filters</Typography>
        {renderCheckboxes}
      </Dialog>
    </>
  );
};

export default memo(EditGoalFilters);
