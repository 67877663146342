import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Container } from '@sprnova/nebula';
import { useGetClientFusionIntegrationsPublicQuery } from 'api/crudGraphQL/public/clients/getClientFusionIntegrationsPublic';
import { useGetNovaFeaturesPublicQuery } from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import { useGetScoreboardsPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardsPublic';
import { Warning } from 'components/AccessControl/Warning';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { PageHero } from 'layouts/components';
import { novaFeaturesProjection } from 'features/clients/External/novaFeaturesProjection';
import { hasFeatureAccess } from 'features/clients/External/utils/hasFeatureAccess';
import { useAccount } from 'features/global/hooks/useAccount';
import { fusionIntegrationsProjection } from 'features/reports/EditReportPage/projection';
import { FilterProvider, Filters } from './components/Filters';
import { track } from './components/mixpanelEvents';
import MyAccounts from './components/MyAccounts';
import NoIntegrationsAlertBanner from './components/NoIntegrationsAlertBanner';
import { myAccountsExternalProjection } from './components/projections';

const ExternalScoreboardsListPage: FC = () => {
  const mixpanel = useMixpanel();
  const { clientId: id } = useParams<{ [x: string]: string }>();
  const { account, isLoading } = useAccount();
  const clientId = parseInt(id);
  const [tracked, setTracked] = useState(false);

  const { filter, setFilter } = Filters({
    page: 1,
    limit: 10,
  });

  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({ client_id: clientId, projection: novaFeaturesProjection }, { skip: !clientId });

  // Client integrations data
  const { data: novaIntegrationsData } = useGetClientFusionIntegrationsPublicQuery(
    { clientId, projection: fusionIntegrationsProjection },
    { skip: !clientId }
  );

  // Determine whether client has data based on length
  const hasNovaIntegrations = novaIntegrationsData && novaIntegrationsData.length;

  useEffect(() => {
    if (account && !tracked) {
      const { id, name, email } = account;
      track({ mixpanel, type: 'external', options: { user_id: id, user_name: name, email } });
      setTracked(true);
    }
  }, [account, mixpanel, tracked]);

  const hasAccess = useMemo(() => {
    return hasFeatureAccess(nova_features, 'scoreboards');
  }, [nova_features]);

  if (isLoading || isLoadingFeatures) {
    return <PageSkeleton />;
  }

  /**
   * Show alert if current client does not have access to this feature
   */
  if (!hasAccess && !isLoadingFeatures) {
    return <Warning container />;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <PageHero
        title="Scoreboards"
        breadcrumbs={<Breadcrumbs items={[
          {name: 'Home', path: '/'},
          {name: 'Scoreboards', path: `/c/${clientId}scoreboards`},
        ]}
        />}
      />
      <Container hasVerticalPadding>
        <Box sx={{ height: 'auto' }}>
          <FilterProvider filter={{ filter, setFilter }}>
            {!hasNovaIntegrations && <NoIntegrationsAlertBanner clientId={clientId} isExternalUser/>}
            <MyAccounts
              query={useGetScoreboardsPublicQuery}
              projection={myAccountsExternalProjection}
              isExternal
              clientId={clientId}
            />
          </FilterProvider>
        </Box>
      </Container>
    </Box>
  );
};

export default memo(ExternalScoreboardsListPage);

