import React, { ReactNode, useMemo } from 'react';
import { Role } from 'api/accessControl/Role';
import { AccountAssignmentIcon } from 'components/Icon/svg/AccountAssignmentIcon';
import { BlueprintReportinIcon } from 'components/Icon/svg/BlueprintReportinIcon';
import { ClientsIcon } from 'components/Icon/svg/ClientsIcon';
import { PerformanceIcon } from 'components/Icon/svg/PerformanceIcon';
import { ServiceExpansionIcon } from 'components/Icon/svg/ServiceExpansionIcon';
import { LABELS_TYPES } from 'utils';
import { useRoleAccess } from 'features/global/hooks/useRoleAccess';

const POWER_VIEW_BASE_PATH = '/power-view';

export const usePowerViewMenuItems = (): {
  id: number;
  name: string;
  path: string;
  is_authorized: boolean;
  illustration: ReactNode;
}[] => {
  const isRetoolUser = useRoleAccess([Role.superAdmin, Role.cLevel, Role.vpLevel, Role.departmentDirectors, Role.retoolUser]);

  const items = useMemo(()=> [
    {
      id: 1001,
      name: LABELS_TYPES.CAPITALIZE_CLIENTS,
      path: `${POWER_VIEW_BASE_PATH}/accounts`,
      is_authorized: true,
      illustration: <ClientsIcon />,
    },
    {
      id: 1002,
      name: `${LABELS_TYPES.CAPITALIZE_CLIENTS} Assignment`,
      path: `${POWER_VIEW_BASE_PATH}/account-assignments`,
      is_authorized: isRetoolUser,
      illustration: <AccountAssignmentIcon />,
    },
    {
      id: 1003,
      name: 'Service Expansion',
      path: `${POWER_VIEW_BASE_PATH}/service-expansion`,
      is_authorized: isRetoolUser,
      illustration: <ServiceExpansionIcon />,
    },
    {
      id: 1004,
      name: 'Performance',
      path: `${POWER_VIEW_BASE_PATH}/performance`,
      is_authorized: true,
      illustration: <PerformanceIcon />,
    },
    {
      id: 1006,
      name: `${LABELS_TYPES.CAPITALIZE_CLIENT} Reporting`,
      path: `${POWER_VIEW_BASE_PATH}/client-reporting`,
      is_authorized: true,
      illustration: <BlueprintReportinIcon />,
    },
  ],[isRetoolUser]);

  return items;
};
