/**
 * Format a date to a string in the format MM/DD/YYYY
 * @param Date | string - the date to format
 * @returns string in the format MM/DD/YYYY
 */
export const formatLocalDateToMMDDYYYY = (date: Date | string): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear();
};

/**
 * Format a date to a string in the format MM/DD/YYYY
 * @param Date | string - the date to format
 * @returns string in the format MM/DD/YYYY in UTC
 */
export const formatUTCDateToMMDDYYYY = (date: Date | string): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return (date.getUTCMonth() + 1).toString().padStart(2, '0') + '/' + date.getUTCDate().toString().padStart(2, '0') + '/' + date.getUTCFullYear();
};


/**
 * Converts a date to a string in the format YYYY-MM-DD
 * @param Date | string - the date to format
 * @returns the date as a string in the format YYYY-MM-DD
 */
export const formatDateToYYYY_MM_DD = (date: Date | string): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
};

/**
 * Converts a date to a string in the format Month Day, Year
 * @param Date | string - the date to format
 * @returns the date as a string in the format Month Day, Year
 * Ex. January 20th, 2024
 */
export const formatFullDate = (date: Date | string, excludeSuffix = false, convertToUTC = false): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if(convertToUTC) {
    date = getUTCDateFromLocalDate(date);
  }
  const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

  // Get the day suffix (st, nd, rd, or th)
  const day = date.getUTCDate();
  let daySuffix;
  if (day >= 11 && day <= 13) {
    daySuffix = 'th';
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = 'st';
        break;
      case 2:
        daySuffix = 'nd';
        break;
      case 3:
        daySuffix = 'rd';
        break;
      default:
        daySuffix = 'th';
    }
  }

  if(excludeSuffix) {
    return formattedDate;
  }
  // Append the day suffix to the formatted date
  return formattedDate.replace(',', `${daySuffix},`);
};

/**
 * Convert local time date to UTC date
 *
 * If you only pass a date string into a new Date() object like "YYYY-MM-DD",
 * it will default all of the time values to 0 and then adjust the UTC time to local time
 *
 * By adding the timezone offset we can convert the date back to the UTC date we want
 *
 * We convert the timezone offset from minutes to milliseconds by multiplying by 60000
 *
 * @param Date date
 * @returns Date date
 */
export const getUTCDateFromLocalDate = function(date: Date): Date {
  const conversionFactorFromMinutesToMilliseconds = 60000;
  return new Date(date.getTime() + date.getTimezoneOffset() * conversionFactorFromMinutesToMilliseconds);
};

/**
 * Append UTC to datetime to specify that it is a UTC time
 * @param date Datetime string, e.g: 2024-08-15 23:13:15
 */
export const datetimeToUTC = (date: string): string => {
  return date + ' UTC';
};
