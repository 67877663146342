import React, { memo, useCallback, useState } from 'react';
import { Autocomplete, Controller, Grid, MonetaryField, TextField } from '@sprnova/nebula';
import { useGetIndicatorsQuery } from 'api/crudGraphQL/indicators/getIndicators';

const MonthlyGoalForm = ({ control, trigger, defaultMetricValue }: any): JSX.Element => {
  const [metric, setMetric] = useState<string>(defaultMetricValue);

  const { data, isLoading } = useGetIndicatorsQuery({
    goal_pacing: 'yes',
    projection: {
      id: false,
      name: true,
      slug: true,
      format: true,
    },
  });

  const renderInputComponent = useCallback((props) => {
    if (data?.filter((option) => option.name === metric)[0]?.format === '$') {
      return <MonetaryField {...props} />;
    }
    return <TextField {...props} type='number' onWheel={() => {}} InputProps={{ inputProps: { min: 0 } }} />;
  }, [data, metric]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Controller
          name='metric'
          control={control}
          rules={{ required: 'This is a required field' }}
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }): JSX.Element => (
            <Autocomplete
              {...field}
              value={value}
              error={error?.message}
              onChange={(event, newValue) => {
                onChange(newValue);
                setMetric(newValue?.label);
                trigger('metric');
              }}
              id='CreateNewMonthlyGoal-metric'
              label='Metric'
              options={data?.map((option) => ({ label: option.name, id: option.slug })) || []}
              skeleton={!defaultMetricValue && isLoading}
            />
          )}
        />
      </Grid>
      {
        metric && (
          <Grid item xs={12}>
            <Controller
              name='goal'
              control={control}
              rules={{ required: 'This is a required field' }}
              render={({
                field: { onChange, value, ...field },
                fieldState: { error },
              }): JSX.Element => (
                renderInputComponent({
                  ...field,
                  value,
                  error: error?.message,
                  onChange: (event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    onChange(event?.target?.value);
                    trigger('metric');
                  },
                  id: 'CreateNewMonthlyGoal-goal',
                  label: `Monthly ${metric} Goal`,
                })
              )}
            />
          </Grid>
        )
      }
    </Grid>
  );
};

export default memo(MonthlyGoalForm);
