import { Projection } from 'api/entityGraphQL';


export const defaultClientProjection: Projection = {
  id: true,
  name: true,
  legal_name: true,
  website: true,
  logo: true,
  author: {
    id: true,
    name: true,
  },
  accounts: {
    id: true,
    auth_method: true,
    is_enabled: true,
    is_ingesting: true,
    data_source_account_id: true,
    url: true,
    value2: true,
    progress: true,
    reauthenticate_url: true,
    is_created_by_client: true,
    account: {
      id: true,
      slug: true,
      name: true,
      description: true,
      is_refreshable: true,
      group: true,
      disclaimer: true,
    },
    meta_page_id: true,
  },
  account_manager: {
    id: true,
    name: true,
  },
  executive_sponsor: {
    id: true,
    name: true,
  },
  group_director: {
    id: true,
    name: true,
  },
  am_specialist: {
    id: true,
    name: true,
  },
  organization: {
    id: true,
    name: true,
  },
  business_type: {
    id: true,
    name: true,
  },
  industry: {
    id: true,
    name: true,
  },
  lead_source: {
    id: true,
    name: true,
  },
  status: true,
  audits: {
    id: true,
    name: true,
    created_at: true,
    client: { id: true, name: true },
    salesforce_opportunity_id: true,
  },
  contacts: {
    id: true,
    name: true,
    title: true,
    email: true,
    phone: true,
  },
  reports: {
    id: true,
    name: true,
    logo: true,
    business_type: {
      name: true,
    },
    client: {
      id: true,
      name: true,
      account_manager: {
        name: true,
        avatar: true,
      },
      executive_sponsor: {
        name: true,
        avatar: true,
      },
    },
  },
  strategies: {
    id: true,
    name: true,
    type: true,
    total_retainer: true,
    monthly_gross_profit: true,
    length: true,
    aging: true,
    hourly_rate: true,
    status: true,
    salesforce_is_primary: true,
    salesforce_opportunity_id: true,
    salesforce_opportunity_name: true,
    lost_reason_type: true,
    follow_up_date: true,
    detailed_reason: true,
    author: {
      id: true,
      name: true,
    },
    client: {
      id: true,
      business_type: {
        id: true,
      },
      salesforce_client_id: true,
    },
  },
  users: {
    name: true,
    id: true,
    avatar: true,
  },
  integration_requests: {
    reminded_at: true,
    requests: {
      data_source_id: true,
      access_request_id: true,
      access_request_status: true,
      access_request_created_at: true,
      manual_access_request_id: true,
      manual_access_request_status: true,
      manual_access_request_created_at: true,
    }
  },
  salesforce_client_id: true,
  salesforce_client_name: true,
  netsuite_id: true,
  tier: {
    id: true,
    name: true,
    slug: true,
  },
  fivetran_connectors: {
    id: true,
    client_id: true,
    data_source_id: true,
    connector_id: true,
    connector_card_auth: true,
    schema_name: true,
    created_by: true,
    progress: true,
    data_source: {
      slug: true,
      name: true
    }
  },
  group_id: true,
  group_destination: true,
  created_at: true,
  onboarded_at: true,
};

/** Default client integration projection to only use data that is needed. */
export const defaultClientIntegrationProjection: Projection = {
  accounts: {
    auth_method: true,
    account: {
      description: true,
      disclaimer: true,
      group: true,
      id: true,
      is_refreshable: true,
      max_refresh: true,
      name: true,
      slug: true,
    },
    meta_page_id: true,
    data_source_account_id: true,
    id: true,
    is_created_by_client: true,
    is_enabled: true,
    is_ingesting: true,
    progress: true,
    reauthenticate_url: true,
    url: true,
    value2: true,
  },
  id: true,
  integration_requests: {
    reminded_at: true,
    requests: {
      data_source_id: true,
      access_request_id: true,
      access_request_status: true,
      access_request_created_at: true,
      manual_access_request_id: true,
      manual_access_request_status: true,
      manual_access_request_created_at: true,
    }
  },
  name: true,
  status: true,
  users: {
    name: true,
    id: true,
  },
  fivetran_connectors: {
    id: true,
    client_id: true,
    data_source_id: true,
    connector_id: true,
    connector_card_auth: true,
    schema_name: true,
    created_by: true,
    progress: true,
    data_source: {
      slug: true,
      name: true
    }
  },
  group_id: true,
  group_destination: true,
};

/** Default client integration progress projection */
export const defaultClientIntegrationProgressProjection: Projection = {
  accounts: {
    auth_method: true,
    account: {
      description: true,
      disclaimer: true,
      group: true,
      id: true,
      is_refreshable: true,
      max_refresh: true,
      name: true,
      slug: true,
    },
    meta_page_id: true,
    data_source_account_id: true,
    id: true,
    is_created_by_client: true,
    is_enabled: true,
    is_ingesting: true,
    progress: true,
    reauthenticate_url: true,
    url: true,
    value2: true,
  },
  id: true,
  integration_requests: {
    reminded_at: true,
    requests: {
      data_source_id: true,
      access_request_id: true,
      access_request_status: true,
      access_request_created_at: true,
      manual_access_request_id: true,
      manual_access_request_status: true,
      manual_access_request_created_at: true,
    }
  },
  fivetran_connectors: {
    id: true,
    client_id: true,
    data_source_id: true,
    connector_id: true,
    connector_card_auth: true,
    schema_name: true,
    created_by: true,
    progress: true,
    data_source: {
      slug: true,
      name: true
    }
  },
  group_id: true,
  group_destination: true,
};

export const invoiceProjection: Projection = {
  invoice_name: true,
  term_name: true,
  amount: true,
  amount_paid: true,
  amount_unpaid: true,
  due_date: true,
  status_name: true,
  start_date: true,
  end_date: true,
  close_date: true,
  invoice_date: true,
  days_open: true,
  created_at: true,
  details: {
    transaction_id: true,
    service_name: true,
    department_name: true,
    amount: true,
    amount_paid: true,
    amount_unpaid: true,
    due_date: true,
    description: true,
    invoice_date: true,
    status_name: true,
    created_at: true,
    close_date: true,
  },
};

export const netsuiteProjection: Projection = {
  client_id: true,
  client_name: true,
  last_payment_amount: true,
  last_payment_due_date: true,
  last_payment_close_date: true,
  upcoming_payment_amount: true,
  upcoming_payment_due_date: true,
  contract_terms_term_name: true,
  overdue_payment_amount: true,
  overdue_payment_due_date: true,
  open_invoices: invoiceProjection,
  closed_invoices: invoiceProjection,
};
