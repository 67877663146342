import React from 'react';
import {
  CartesianGrid,
  Cell,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from 'recharts';
import { HorizonPerformance } from 'features/entitiesRedux/models/horizon';
import css from './HorizonScatterChart.module.scss';

const COLORS = [
  '#D3F3A1',
  '#AAE3A5',
  '#81D4A9',
  '#57C4AD',
  '#2EB5B1',
  '#05A5B5',
  '#11909F',
  '#2D818E',
  '#2A6672',
  '#203E4A',
];

type HorizonScatterChartProps = {
  performances?: HorizonPerformance[];
  isForecast?: boolean;
  title?: React.ReactNode,
  subtitle?: React.ReactNode,
}

type LabelType = {
  x: number;
  y: number;
  cost: number;
  roas: number;
  channel: string;
}

const HorizonScatterChart = ({ performances, isForecast, title, subtitle }: HorizonScatterChartProps): JSX.Element => {
  const formattedData = performances?.map((performance) => {
    return {
      x: performance?.roas_pct_rank,
      y: performance?.cost_pct_rank,
      roas: performance?.roas,
      cost: performance?.cost,
      channel: `${performance?.channel?.name} (${performance?.funnel?.name})`
    };
  });

  const yAxisTickFormatter = (tickValue: number): string | undefined => {
    if (tickValue == 0) return 'Low';
    if (tickValue == .333333) return 'Med';
    if (tickValue == .666666) return 'High';
    if (tickValue == 1) return '';
  };

  const xAxisTickFormatter = (tickValue: number): string | undefined => {
    if (tickValue == .166666) return 'Low';
    if (tickValue == .5) return 'Med';
    if (tickValue == .833333) return 'High';
    return '';
  };

  const CustomizedLabel = (props: LabelProps): JSX.Element => {
    const {x, y, value} = props;

    // get current label
    const currentLabel: LabelType | undefined = formattedData?.filter(i => i?.channel === value)?.[0];
    // find all labels with matching coordinates
    const matchingLabels = formattedData?.filter(el => el?.x === currentLabel?.x && el?.y === currentLabel?.y);
    // get the index of our current label from our matchingLabels array
    const currentLabelIndex = currentLabel && matchingLabels?.indexOf(currentLabel) || 0;
    // calculate dy offset based on index of current label
    const yOffset = y && y + 10 + (15 * currentLabelIndex);
    const xOffset = x && x - 65;

    return (
      <g>
        <foreignObject x={xOffset} y={yOffset} fontSize={12} className={css.labelContainer}>
          <text>{value}</text>
        </foreignObject>
      </g>
    );
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        {title}
      </div>
      <div className={css.subTitle}>
        {subtitle}
      </div>
      <ResponsiveContainer width="100%" minHeight={500}>
        <ScatterChart
          width={1300}
          height={450}
          margin={{
            top: 5,
            right: 100,
            left: 50,
            bottom: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="x"
            name="roas"
            ticks={[0, .166666, .333333, .5, .666666, .833333, 1]}
            tickLine={false}
            domain={[0, 1]}
            label={{ value: 'Performance', dy: 25, fontSize: 12, fill: '#838383' }}
            tickFormatter={xAxisTickFormatter}
            height={50}
            dy={10}
          />
          <YAxis
            type="number"
            dataKey="y"
            name="cost"
            ticks={[0, .333333, .666666, 1]}
            tickLine={false}
            domain={[0, 1]}
            label={{ value: isForecast ? 'Budget Scenario Spend Levels' : 'Historical Spend Volume', angle: -90, dx: -50, fontSize: 12, fill: '#838383' }}
            tickFormatter={yAxisTickFormatter} dy={-70}
            dx={-10}
          />
          <Scatter name="Tactic Performance" data={formattedData} fill="#8884d8" points={[{ cx: 12, cy: 12, payload: {x: 12, y: 45, z: 9 }}]}>
            <LabelList dataKey='channel' position='bottom' content={CustomizedLabel}/>
            {formattedData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[Math.floor((entry?.y * 10))]} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HorizonScatterChart;
