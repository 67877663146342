import React, { memo } from 'react';
import { config } from 'config';
import { GoogleModal } from 'features/clients/components/AddIntegrationLink/utils/GoogleModal';
import { Client, DataSource, ExternalClient } from 'features/entitiesRedux';

type GoogleAdsDialogProps = {
  client?: Client | ExternalClient;
  datasource?: DataSource;
  isClient?: boolean;
  userId?: number;
  openModal?: boolean;
  renderButton?: boolean;
  onClose?: () => void;
};

/**
 * GoogleAdsDialog Component
 *
 * This component displays a dialog specific to Google Ads integration.
 * It renders GoogleModal with Google Ads specific content and an attribution dropdown.
 *
 * @param {GoogleAdsDialogProps} props - Props containing client, datasource, isClient, and userId.
 * @returns {JSX.Element} - The rendered dialog component.
 */
const GoogleAdsDialog: React.FC<GoogleAdsDialogProps> = ({
  datasource,
  client,
  isClient,
  userId,
  openModal = false,
  renderButton = true,
  onClose
}) => {
  // Define the scope and access type for Google Ads API
  const scope = 'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email';
  const accessType = 'offline';

  // Retrieve the host configuration from the config file
  const host = config?.host;

  // Determine the user type based on the isClient flag
  const userType = isClient ? 'external' : 'internal';

  // Extract redirect URI, client ID, auth URL, and other details from the datasource and client
  const redirectUri = datasource?.nova_cloud_redirect_uri ?? '';
  const clientId = client?.id ?? '';
  const datasourceAuthUrl = datasource?.auth_url ?? '';
  const datasourceId = datasource?.id ?? '';
  const datasourceName  = datasource?.name ?? '';
  const datasourceSlug = datasource?.slug ?? '';
  const googleClientId = datasource?.nova_cloud_client_id ?? '';

  // For local development, the API is running on a different port
  // Uncomment the following line for local testing
  // const redirectUrl = 'http://localhost:8888/authorize/novacloud/google-auth/callback';

  // Construct the redirect URL using the host and redirect URI
  const redirectUrl = host?.concat(redirectUri);

  // Construct the state parameter to pass to the auth callback
  const state = `clientId;${clientId},datasourceId;${datasourceId},userId;${userId}${userType === 'internal' ? '' : ',initiatedBy;client'}`;

  // Build the Google Ads authorization link
  const authLink = `${datasourceAuthUrl}?prompt=consent&response_type=code&client_id=${googleClientId}&scope=${scope}&access_type=${accessType}&redirect_uri=${redirectUrl}&state=${state}&flowName=GeneralOAuthFlow`;

  return (
    <GoogleModal
      dataSourceName={datasourceName}
      dataSourceSlug={datasourceSlug}
      authLink={authLink}
      clientId={client?.id}
      openModal={openModal}
      renderButton={renderButton}
      onClose={onClose}
    />
  );
};

export default memo(GoogleAdsDialog);
