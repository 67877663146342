/**
* Get departments
*/

import { Projection } from 'api/entityGraphQL/types';
import { Department } from 'features/entitiesRedux/models/department/department';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';

export type GetDepartmentsQuery = {
  id?: number;
  name?: string;
  projection: Projection;
};

export type GetDepartmentsResult = Department[];

export const GET_DEPARTMENTS_ENDPOINT_KEY = 'getDepartments';

export const { useGetDepartmentsQuery, useLazyGetDepartmentsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_DEPARTMENTS_ENDPOINT_KEY]: build.query<GetDepartmentsResult, GetDepartmentsQuery>({
      transformResponse: (response: { departments: GetDepartmentsResult }) => response.departments,
      providesTags: (departments = []) => [
        tagTypes.DEPARTMENTS,
        ...departments.map(department => ({
          id: department.id,
          type: tagTypes.DEPARTMENT
        }))
      ],
      query: ({
        projection = { id: true, name: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetDepartmentsQuery> = args;

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              departments: {
                __args: cleanArguments(__args),
                ...projection
              },
            }
          }
        };
      },
    }),
  }),
});
