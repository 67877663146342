/**
 * Get users
 */

import { baseApi, cleanArguments, paginationProjection } from 'api/crudGraphQL';
import { Pagination, Projection } from 'api/entityGraphQL';
import { User } from 'features/entitiesRedux';
import tagTypes from './tagTypes';

export type GetUsersQuery = {
  id?: number;
  ids?: number[];
  department_id?: number;
  departments?: number[];
  exclude_roles?: string[];
  name?: string;
  email?: string;
  roles?: string[];
  talent?: boolean;
  survey_cycle_id?: number;
  survey_cycle_experience_status?: string;
  page?: number;
  limit?: number;
  sort?: string[];
  projection?: Projection;
};

export type GetUsersResult = Pagination & {
  data: User[]
};

export const { useGetUsersQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<GetUsersResult, GetUsersQuery>({
      transformResponse: (response: { users: GetUsersResult }) => response.users,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.USERS,
          ...data.map((user: User) => ({
            id: user.id,
            type: tagTypes.USER
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        page = 1,
        limit = 10,
        name,
        ...args
      }) => {
        const __args: Partial<GetUsersQuery> = {
          page,
          limit,
          ...args,
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              users: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});
