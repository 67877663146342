/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumbs, Container, EmptyState, Hero, Skeleton } from '@sprnova/nebula';
import { Action, Resource, Role } from 'api/accessControl';
import { useGetStrategiesQuery } from 'api/crudGraphQL/strategies/getStrategies';
import { Warning } from 'components/AccessControl/Warning';
import { omitBy } from 'lodash';
import { Strategy } from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks';
import { PricingVersion } from 'features/library/constants';
import { AccessControl, Spin, notification } from 'components';
import { BlueprintsPackageReviewList } from './components';
import BlueprintsPackageReviewQueueFilter from './components/BlueprintsPackageReviewFilter';
import { blueprintsProjection } from './projection';
import { BLUEPRINT_REVIEWER_TYPE } from '../constants';
import css from './BlueprintsPackageReviewPage.module.scss';

type StrategiesResultType = {
  data: Strategy[];
  total: number;
}

const BlueprintsPackageReviewPage = (): JSX.Element => {

  const { account, isLoading: accountIsLoading, error: accountError } = useAccount();
  const hasNotBlueprintsAccess = account ?
    account?.roles.includes(Role.partner)
    || account?.roles.includes(Role.client)
    || account?.roles.includes(Role.contractor)
    : false;

  const blueprintsPackageReviewPageRef = useRef<any>(null);

  const isFinanceReviewed = useMemo(() => {
    return (account?.roles &&
      (account.roles.includes(Role.accounting) || account.roles.includes(Role.cLevel) ||
        account.roles.includes(Role.vpLevel)) &&
      account.roles.includes(Role.blueprintsReview));
  }, [account]);

  const isSuperAdminBP = useMemo(() => {
    return  (account?.roles &&
      account.roles.includes(Role.superAdmin));
  }, [account]);

  const isDepartmentReviewed = useMemo(() => {
    return (account?.roles && !isFinanceReviewed && !isSuperAdminBP &&
      (account.roles.includes(Role.departmentDirectors)
        || account.roles.includes(Role.blueprintsReview)));
  }, [account, isFinanceReviewed, isSuperAdminBP]);

  const showDepartmentPillars = useMemo(() => [1001, 1002, 1004], []);
  const departmentIds = useMemo(() => account?.departments?.map((department) => department.id), [account]);
  const departmentNames = useMemo(() =>  account?.departments?.map((department) => department.name).join(' and '), [account]);

  const [filter, setFilter] = useState<Record<string, any>>({
    page: 1,
    limit: 25, // max number of blueprints to fetch and handle pagination on the FE
    pricing_version: PricingVersion.PACKAGE,
    status: 'won',
    sort: ['-updated_at'],
  });
  const hasFilters = !!Object.keys(omitBy(filter, (val: any) => !val)).length;

  /**
   * RTK fetch strategy
   */
  const { data: strategiesData, isLoading, isFetching, error } = useGetStrategiesQuery({
    ...filter,
    pricing_version: PricingVersion.PACKAGE,
    projection: blueprintsProjection,
  }, {
    refetchOnMountOrArgChange: true
  });

  /**
   * Extracting only unique BP ids from the array. This is needed because the API returns duplicate strategies
   */
  const getUniqueDataIdStrategies = useMemo(() => {
    const result: Record<string, any> = {};
    if (strategiesData?.data) {
      strategiesData.data.forEach((strategy) => {
        result[strategy.id] = strategy;
      });
    }
    return result;
  } , [strategiesData]);

  /**
   * Object with two key/value pairs to memoize the strategy data:
   * - data: array of strategies
   * - total: total number of strategies (for FE pagination)
   */
  const strategies: StrategiesResultType = useMemo(() => {
    const result: StrategiesResultType = {
      data: [],
      total: 0,
    };
    result.data = getUniqueDataIdStrategies ? Object.values(getUniqueDataIdStrategies) : [];
    result.total = result.data ? result.data.length : 0;

    return result;
  }, [getUniqueDataIdStrategies]);

  const loading = isLoading || (strategies?.data.length === 0 && !hasFilters);
  // Have to reset the filter after the account loads
  useEffect(() => {
    setFilter({
      ...filter,
      is_finance_reviewed: isFinanceReviewed ? false : isDepartmentReviewed ? true : undefined,
      is_department_heads_reviewed: isDepartmentReviewed ? false : undefined,
      department_ids: isDepartmentReviewed ? departmentIds : undefined,
      is_department_pending_review: isDepartmentReviewed ? 'yes' : undefined,
      pillar_ids: isDepartmentReviewed ? showDepartmentPillars : undefined,
    });
    // disable the eslint rule because we don't want to run this effect when the filter changes,
    // only when an account changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentIds, isDepartmentReviewed, isFinanceReviewed, showDepartmentPillars]);

  /**
   * Clear the filters and reset to initial filters
   * @returns void
   */
  const clearFilter = useCallback((): void => {
    blueprintsPackageReviewPageRef.current?.clearFilter();
    setFilter({
      page: 1,
      limit: 25, // max number of blueprints to fetch and handle pagination on the FE
      pricing_version: PricingVersion.PACKAGE,
      status: 'won',
      sort: ['-updated_at'],
      name: '',
      is_finance_reviewed: isFinanceReviewed ? false : isDepartmentReviewed ? true : undefined,
      is_department_heads_reviewed: isDepartmentReviewed ? false : undefined,
      department_ids: isDepartmentReviewed ? departmentIds : undefined,
      is_department_pending_review: isDepartmentReviewed ? 'yes' : undefined,
      pillar_ids: isDepartmentReviewed ? showDepartmentPillars : undefined,
    });
  }, [departmentIds, isDepartmentReviewed, isFinanceReviewed, showDepartmentPillars]);

  /**
   * Render the different filters that the blueprints list can be filtered by
   * @returns JSX.Element The different filters
   */
  const renderBlueprintReviewQueueFilterButton = useCallback((): JSX.Element => {
    if (isLoading) return <Skeleton height={100} />;
    return (
      <AccessControl action={[Action.update]} resource={Resource.packageStrategy}>
        <BlueprintsPackageReviewQueueFilter
          setFilter={setFilter}
          className={css.container__filter}
          ref={blueprintsPackageReviewPageRef}
        />
      </AccessControl>
    );
  }, [isLoading]);

  /**
   * Render the blueprints package review queue table
   * @returns JSX.Element
   */
  const renderBlueprintsPackageReviewListTable = useCallback((): JSX.Element => {
    if (strategies.data.length === 0) {
      return (
        <>
          {isFetching && <Spin />}
          <EmptyState
            sx={{ marginTop: '24px' }}
            size="large"
            title="No Blueprints to be reviewed found"
            description="No Blueprints to be reviewed found with the current filters. Try clearing your filters."
            buttonProps={{
              children: 'Clear filters',
              // reset to initial filters
              onClick: () => clearFilter(),
            }}
          />
        </>
      );
    } else {
      return (
        <Container hasVerticalPadding>
          <AccessControl action={[Action.update]} resource={Resource.strategy}>
            <>
              {isFetching && <Spin />}
              <BlueprintsPackageReviewList
                error={error}
                filter={filter}
                isLoading={isLoading}
                strategies={strategies.data}
                strategiesTotal={strategies.total}
                reviewerType={isFinanceReviewed || isSuperAdminBP ? BLUEPRINT_REVIEWER_TYPE.Finance : BLUEPRINT_REVIEWER_TYPE.Department}
              />
            </>
          </AccessControl>
        </Container>
      );
    }
  }, [clearFilter, error, filter, isFetching, isFinanceReviewed, isLoading, isSuperAdminBP, strategies.data, strategies.total]);

  const renderComponentLoadingState = useCallback(() => {
    if (loading || accountIsLoading) {
      return (
        <Container hasVerticalPadding><Skeleton height={140} /></Container>
      );
    }
  }, [accountIsLoading, loading]);
  /**
   * Render the component:
   * - If the user is not logged in, redirect to the home page
   * - If the user is logged in, but does not have access to the blueprints, show a warning
   * - If the user is logged in and has access to the blueprints, show the blueprint review page
   */
  const renderComponent = useCallback((): JSX.Element => {
    renderComponentLoadingState();
    if (account) {
      if (hasNotBlueprintsAccess) {
        return <Container hasVerticalPadding><Warning container /></Container>;
      } else {
        return (
          <>
            <Hero
              breadcrumbs={<Breadcrumbs items={[
                { name: 'Home', path: '/' },
                { name: 'Blueprints (Package)', path: '/blueprints/package' },
              ]} />}
              description='All closed deals needing approval'
              title={isFinanceReviewed ? 'Blueprints (Package) Review Queue - Finance' : isDepartmentReviewed ? `Blueprints (Package) Review Queue - ${departmentNames} department` : 'Blueprints (Package) Review Queue'}
              // eslint-disable-next-line react/no-children-prop
              children={renderBlueprintReviewQueueFilterButton()}
            />
            {renderBlueprintsPackageReviewListTable()}
          </>
        );
      }
    }
    return <></>;
  }, [account, departmentNames, hasNotBlueprintsAccess, isDepartmentReviewed, isFinanceReviewed, renderBlueprintReviewQueueFilterButton, renderBlueprintsPackageReviewListTable, renderComponentLoadingState]);

  // For better UX, change the cursor to wait when data are fetching
  useEffect(() => {
    document.body.style.cursor = isFetching ? 'wait' : 'default';
  }, [isFetching]);

  if (accountError) {
    notification.error({
      message: accountError
    });
  }

  return (
    <>
      {renderComponent()}
    </>
  );
};

const Title = (): JSX.Element => <>Blueprints Package Review Queue</>;
Title.displayName = 'BlueprintsPackageReviewPageTitle';
export default Object.assign(BlueprintsPackageReviewPage, { Title });
