import React, { ReactNode, useMemo } from 'react';
import { Role } from 'api/accessControl/Role';
import { HourlyPricingIcon } from 'components/Icon/svg/HourlyPricingIcon';
import { PackagePricingIcon } from 'components/Icon/svg/PackagePricingIcon';
import { ContractAiIcon } from 'components/Icon/svg/ContractAiIcon';
import { BlueprintReportinIcon } from 'components/Icon/svg/BlueprintReportinIcon';
import { useRoleAccess } from 'features/global/hooks/useRoleAccess';

const BLUEPRINTS_BASE_PATH = '/blueprints';

export const useBlueprintMenuItems = (): {
  id: number;
  name: string;
  path: string;
  is_authorized: boolean;
  illustration: ReactNode;
}[] => {
  const isRetoolUser = useRoleAccess([Role.superAdmin, Role.cLevel, Role.vpLevel, Role.departmentDirectors, Role.retoolUser]);

  const items = useMemo(()=> [
    {
      id: 1002,
      name: 'Package Pricing',
      path: `${BLUEPRINTS_BASE_PATH}/package`,
      is_authorized: true,
      illustration: <PackagePricingIcon />,
    },
    {
      id: 1001,
      name: 'Hourly Pricing',
      path: `${BLUEPRINTS_BASE_PATH}/hourly`,
      is_authorized: true,
      illustration: <HourlyPricingIcon />,
    },
    {
      id: 1003,
      name: 'Contract AI',
      path: `${BLUEPRINTS_BASE_PATH}/contract-ai`,
      is_authorized: isRetoolUser,
      illustration: <ContractAiIcon />,
    },
    {
      id: 1004,
      name: 'Blueprint Reporting',
      path: `${BLUEPRINTS_BASE_PATH}/reporting`,
      is_authorized: isRetoolUser,
      illustration: <BlueprintReportinIcon />,
    },
  ],[isRetoolUser]);

  return items;
};
