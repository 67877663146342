import { basePublicApi } from '../basePublicApi';
import { NovaAiChallengeType } from 'features/entitiesRedux/models/nova_ai/novaAI';
import { cleanArguments } from 'api/crudGraphQL/utils';
import { Projection } from 'api/entityGraphQL/types';
import tagTypes from './tagTypes';

export type GetNovaChallengesPublicQuery = {
  client_id: number;
  projection?: Projection;
};

export type GetNovaChallengesPublicResult = NovaAiChallengeType[];

export const GET_NOVA_CHALLENGES_PUBLIC_ENDPOINT_KEY = 'getNovaChallengesPublic';

export const { useGetNovaChallengesPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_CHALLENGES_PUBLIC_ENDPOINT_KEY]: build.query<GetNovaChallengesPublicResult, GetNovaChallengesPublicQuery>({
      transformResponse: (response: { nova_challenges: GetNovaChallengesPublicResult }) => response.nova_challenges,
      providesTags: (result) => {
        const data = result ?? [];

        return [
          tagTypes.CHALLENGES,
          ...data.map((challenge) => ({
            id: challenge.id,
            type: tagTypes.CHALLENGE
          }))
        ];
      },
      query: ({
        projection = {
          status: true,
          step: true,
        },
        ...args
      }) => ({
        body: {
          query: {
            nova_challenges: {
              __args: cleanArguments(args),
              ...projection
            },
          }
        }
      })
    }),
  }),
});
