import React, { memo } from 'react';
import { config } from 'config';
import { GoogleModal } from 'features/clients/components/AddIntegrationLink/utils/GoogleModal';
import { Client, DataSource, ExternalClient } from 'features/entitiesRedux';

type GoogleSearchConsoleDialogProps = {
  client?: Client | ExternalClient;
  datasource?: DataSource;
  isClient?: boolean;
  userId?: number;
  openModal?: boolean;
  renderButton?: boolean;
  onClose?: () => void;
};

/**
 * GoogleSearchConsoleDialog Component
 *
 * This component displays a dialog specific to Google Search Console integration.
 * It renders GoogleModal with Google Search Console specific content.
 *
 * @param {GoogleSearchConsoleDialogProps} props - Props containing client, datasource, isClient, and userId.
 * @returns {JSX.Element} - The rendered dialog component.
 */
const GoogleSearchConsoleDialog: React.FC<GoogleSearchConsoleDialogProps> = ({
  datasource,
  client,
  isClient,
  userId,
  openModal = false,
  renderButton = true,
  onClose
}) => {

  // Determine the user type (internal or external)
  const userType = isClient ? 'external' : 'internal';

  // Define the scopes required for Google Search Console API access
  const INTERNAL_SCOPE = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/webmasters.readonly';
  const EXTERNAL_SCOPE = 'https://www.googleapis.com/auth/analytics.manage.users';
  const scope = userType === 'internal' ? INTERNAL_SCOPE : INTERNAL_SCOPE + ' ' + EXTERNAL_SCOPE;

  // Define the access type for the Google Search Console API
  const accessType = 'offline';

  // Retrieve the host configuration from the config file
  const host = config?.host;

  // Extract relevant data from the datasource and client
  const redirectUri = datasource?.nova_cloud_redirect_uri ?? '';
  const clientId = client?.id ?? '';
  const datasourceAuthUrl = datasource?.auth_url ?? '';
  const datasourceId = datasource?.id ?? '';
  const datasourceName  = datasource?.name ?? '';
  const datasourceSlug = datasource?.slug ?? '';
  const googleClientId = datasource?.nova_cloud_client_id ?? '';

  // For local development, the API is running on a different port
  // Uncomment the following line for local testing
  // const redirectUrl = 'http://localhost:8888/authorize/novacloud/google-auth/callback';

  // Construct the redirect URL using the host and redirect URI
  const redirectUrl = host?.concat(redirectUri);

  // Construct the state parameter to pass to the auth callback
  const state = `clientId;${clientId},datasourceId;${datasourceId},userId;${userId}${userType === 'internal' ? '' : ',initiatedBy;client'}`;

  // Build the Google Search Console auth link
  const authLink = `${datasourceAuthUrl}?prompt=consent&response_type=code&client_id=${googleClientId}&scope=${scope}&access_type=${accessType}&redirect_uri=${redirectUrl}&state=${state}&flowName=GeneralOAuthFlow`;

  return (
    <GoogleModal
      dataSourceName={datasourceName}
      dataSourceSlug={datasourceSlug}
      authLink={authLink}
      clientId={client?.id}
      openModal={openModal}
      renderButton={renderButton}
      onClose={onClose}
    />
  );
};

export default memo(GoogleSearchConsoleDialog);
