import Cookies from 'js-cookie';

/**
 * Indicates whether the currently authenticated user is the external user account
 * used for previewing Client Portal.
 *
 * @returns True if the authenticated user is the user for previewing Client Portal; false otherwise.
 */
const useIsClientPortalPreviewUser = (): boolean => {
  const isClientPortalPreviewUser = Cookies.get('client_portal_preview') === 'true';

  return isClientPortalPreviewUser;
};

export default useIsClientPortalPreviewUser;
